import { useRecoilValue } from "recoil";
import { screenStylesState } from "../stores/screen_styles_state";
import { themeColorsState } from "../stores/theme_colors_state";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import { SlRefresh } from "react-icons/sl";
import { MdExpandMore } from "react-icons/md";
import { FiExternalLink } from "react-icons/fi";
import { PiDownloadSimple } from "react-icons/pi";
import { calculateUnitPrice, squareMeterToPyeong } from "../service/calculate";
import AroundInfo from "../components/around_info/around_info";
import SellRealEstateButton from "../components/sell_real_estate_button/sell_real_estate_button";
import SellRealEstatePanel from "../components/sell_real_estate_panel/sell_real_estate_panel";
import BuyRealEstatePanel from "../components/buy_real_estate/buy_real_estate";
import HorizontalLine from "../components/seperators/horizontal_line";

const DetailPage = ({ mapService }) => {
  const screenStyles = useRecoilValue(screenStylesState);
  const colorStyles = useRecoilValue(themeColorsState);

  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");

  const navigation = useNavigate();

  const [resultData, setResultData] = useState([]);

  const [saleState, setSaleState] = useState();
  const [unitState, setUnitState] = useState("meter");
  const [aroundData, setAroundData] = useState([]);

  const [showSellPanel, setShowSellPanel] = useState(false);
  const [showBuyPanel, setShowBuyPanel] = useState(false);

  const [visibleItems, setVisibleItems] = useState(2);
  const [preSaleList, setPreSaleList] = useState([]);

  const [preSaleData, setPreSaleData] = useState([]);
  const [isWanted, setIsWanted] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const mainRef = useRef();

  const handleShowMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 2); // 2개씩 추가로 보여줌
  };

  const getContractState = (obj) => {
    const today = new Date();
    const beginDate = new Date(obj.rcept_bgnde);
    const endDate = new Date(obj.rcept_endde);

    if (today < beginDate) {
      return "future-contract";
    } else if (today > endDate) {
      return "past-contract";
    } else if (today > beginDate && today < endDate) {
      return "now-contract";
    }
  };

  const handleBackBtn = () => {
    navigation(-1);
  };

  const changeUnit = () => {
    if (unitState === "pyeong") {
      setUnitState("meter");
    } else {
      setUnitState("pyeong");
    }
  };

  useEffect(() => {
    scrollToTop();
    const encodedId = searchParams.get("id");
    const tmp_id = atob(encodedId);
    setId(tmp_id);
  }, [searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await mapService.getAptInfoDetail(id);
      setResultData(res?.result.aptInfo[0] || []);
    };
    const fetchAroundData = async () => {
      const res = await mapService.getAroundAptInfo(id);
      // console.log(res);
      setAroundData(res?.result);
    };
    const fetchPreSaleList = async () => {
      const res = await mapService.getPreSaleListbyId(id);
      setPreSaleList(res?.result);
      // console.log(res.result);
    };
    if (id) {
      fetchData();
      fetchAroundData();
      fetchPreSaleList();
    }
    setIsLoading(false);
  }, [showSellPanel, showBuyPanel, id]);

  useEffect(() => {
    // console.log(resultData, Object.keys(resultData).length);
    // console.log(aroundData);
    const _state = getContractState(resultData);
    setSaleState(_state);
  }, [resultData]);

  const handleBuyButtonClick = (obj) => {
    // console.log(obj);

    setPreSaleData({
      id: obj.id,
      presale_id: obj.presale_id,
      dong: obj.dong,
      ho: obj.ho,
      hope_price: obj.hope_price,
      house_type: obj.house_type,
    });
    setIsWanted(true);
    setShowBuyPanel(true);
  };

  const handlePreBuyButtonClick = () => {
    // console.log(resultData);

    setPreSaleData({ presale_id: resultData.id, details: resultData.details });
    setIsWanted(false); //원하는 분양권이 없음
    setShowBuyPanel(true);
  };

  const handleSellButtonClick = (obj) => {
    // console.log(obj);

    setPreSaleData({
      id: obj.id,
      presale_id: obj.presale_id,
      dong: obj.dong,
      ho: obj.ho,
      hope_price: obj.hope_price,
      house_type: obj.house_type,
    });
    setShowSellPanel(true);
  };

  const handleAroundClick = (id) => {
    const encodeId = btoa(id);
    navigation(`/detail?id=${encodeId}`);
  };

  const scrollToTop = () => {
    if (mainRef.current) {
      mainRef.current.scrollTo({
        top: 0,
      });
    }
  };

  const handleOpenUrl = (url) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  const renderContent = () => {
    //전매제한 계산
    const getRestrictionResale = (obj) => {
      // const lrscl = obj.lrscl_bldlnd_at === "Y" ? true : false; //대규모택지개발지구
      // const imprmn = obj.imprmn_bsns_at === "Y" ? true : false; //정비사업
      // const publicHouse = obj.public_house_earth_at === "Y" ? true : false; //수도권내민영공공주택지구
      const mdat = obj.mdat_trget_area_secd === "Y" ? true : false; //조정대상지역
      const npln = obj.npln_prvopr_public_house_at === "Y" ? true : false; //분양가상한제
      const speclt = obj.speclt_rdn_earth_at === "Y" ? true : false; //투기과열지구
      const publicHouseDistrict =
        obj.public_house_spclw_applc_at === "Y" ? true : false; //공공주택지구
      const isCapitalRegion =
        obj.subscrpt_area_code_nm === "서울" ||
        obj.subscrpt_area_code_nm === "경기"
          ? true
          : false; //공급지역명
      const resultList = [];
      speclt && isCapitalRegion ? resultList.push(36) : resultList.push(12);
      mdat && speclt && isCapitalRegion
        ? resultList.push(36)
        : resultList.push(12);
      npln && isCapitalRegion ? resultList.push(36) : resultList.push(12);
      mdat && !speclt && publicHouseDistrict && resultList.push(6);
      const result = Math.max.apply(null, resultList);
      return result ? `${result}개월` : `0개월`;
    };

    const initDate = (startDate, endDate) => {
      // startDate가 없으면 빈 문자열 반환
      if (!startDate) return "";

      // startDate를 'YYYY-MM-DD' 형식으로 변환
      const start = new Date(startDate).toISOString().split("T")[0];

      // endDate가 있으면 'YYYY-MM-DD' 형식으로 변환하고 범위 문자열 반환
      if (endDate) {
        const end = new Date(endDate).toISOString().split("T")[0];
        return `${start} ~ ${end}`;
      }

      // endDate가 없으면 startDate만 반환
      return start;
    };

    const initPrice = (input) => {
      // const price = (Number(input) / 10000).toFixed(1);
      const price = input.toLocaleString();
      return `${price}만원`;
    };

    const initHouseType = (input) => {
      const typeString = input.match(/[a-zA-Z]+/g);
      const typeArea = parseInt(input.split(".")[0]);
      return typeString ? `${typeArea}${typeString}` : `${typeArea}`;
    };

    const initHouseOnlyType = (input) => {
      const typeString = input.match(/[a-zA-Z]+/g);
      return typeString ? typeString : "";
    };

    const getExclusiveArea = (input) => {
      const typeArea = input?.split(".")[0];
      return typeArea ? parseInt(typeArea) : "";
    };

    const formatYearMonth = (input) => {
      if (!input) return "";
      // 입력값을 문자열로 변환
      const inputStr = input.toString();
      // 년도 추출 (앞의 4자리)
      const year = inputStr.slice(0, 4);
      // 월 추출 (뒤의 2자리)
      const month = inputStr.slice(4, 6);

      return `${year}년 ${month}월`;
    };

    //분양 후일 경우
    if (saleState && saleState === "past-contract") {
      return (
        <section>
          <div style={{ marginTop: "16px" }}>
            <span style={{ fontSize: "16px", fontWeight: 700 }}>
              분양권 매물
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              gap: "8px",
              marginTop: "10px",
            }}
          >
            {preSaleList.length > 0 ? (
              preSaleList.slice(0, visibleItems).map((item, index) =>
                item.trade_type === "sell" ? (
                  <div
                    key={`${index}-${item.id}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "32px",
                          height: "20px",
                          textAlign: "center",
                          color: "#0274B4",
                          border: "1.5px solid #0274B4",
                          size: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {"매도"}
                      </div>
                      <span
                        style={{
                          color: colorStyles.red,
                          fontSize: "14px",
                          fontWeight: 600,
                          marginLeft: "5px",
                        }}
                      >
                        {`P ${initPrice(item.hope_price)}`}
                      </span>
                    </div>
                    <span>{`${initHouseType(item.house_type)} | ${
                      item.dong
                    }동 ${Math.floor(item.ho / 100)}층 `}</span>
                    {/* <span>{"계약금 및 중도금(n차)"}</span>
                  <span>{"중개수수료"}</span> */}
                    <span>{item.options.join(", ")}</span>
                    <span>{item.etc}</span>
                    <button
                      style={{
                        backgroundColor: colorStyles.main,
                        color: "white",
                        borderRadius: "8px",
                        border: "none",
                        height: "31px",
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                      onClick={() => handleBuyButtonClick(item)}
                    >
                      {"거래 예약하기"}
                    </button>
                  </div>
                ) : (
                  <div
                    key={`${index}-${item.id}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "32px",
                          height: "20px",
                          textAlign: "center",
                          color: "#0274B4",
                          border: "1.5px solid #0274B4",
                          size: "12px",
                          fontWeight: "bold",
                          margin: "4px 0 8px 0",
                        }}
                      >
                        {"매수"}
                      </div>
                      <span
                        style={{
                          color: colorStyles.red,
                          fontSize: "14px",
                          fontWeight: 600,
                          marginLeft: "5px",
                        }}
                      >
                        {`P ${initPrice(item.hope_price)}`}
                      </span>
                    </div>
                    <span>{`${
                      item.house_type
                        ? initHouseType(item.house_type)
                        : "정보없음"
                    } | ${item.dong}동 ${
                      item.floor ? item.floor : Math.floor(item.ho / 100)
                    }층 `}</span>
                    {/* <span>{"계약금 및 중도금(n차)"}</span>
                  <span>{"중개수수료"}</span> */}
                    <span>{item.options?.join(", ")}</span>
                    {/* <span>{item.etc}</span> */}
                    <button
                      style={{
                        backgroundColor: colorStyles.main,
                        color: "white",
                        borderRadius: "8px",
                        border: "none",
                        height: "31px",
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: "bold",
                        margin: "4px 0 8px 0",
                      }}
                      onClick={() => handleSellButtonClick(item)}
                    >
                      {"거래 예약하기"}
                    </button>
                  </div>
                )
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                  marginTop: "20px",
                  padding: "16px 0",
                  backgroundColor: colorStyles.light_gray,
                  gap: "16px",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: 700 }}>
                  {"아직 등록된 매물이 없습니다"}
                </span>
                <button
                  style={{
                    backgroundColor: colorStyles.main,
                    color: "white",
                    borderRadius: "8px",
                    border: "none",
                    width: "150px",
                    height: "31px",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => setShowSellPanel(true)}
                >
                  {"매물 등록하기"}
                </button>
              </div>
            )}
            {visibleItems < preSaleList.length && (
              <button
                onClick={handleShowMore}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "none",
                  color: colorStyles.gray,
                  border: "none",
                  borderTop: `1px solid ${colorStyles.dark_gray}`,
                  borderBottom: `1px solid ${colorStyles.dark_gray}`,
                  height: "38px",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                더보기 <MdExpandMore size={23} />
              </button>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
              marginTop: "20px",
              padding: "16px 0",
              backgroundColor: colorStyles.light_gray,
              gap: "16px",
            }}
          >
            <span style={{ fontSize: "16px", fontWeight: 700 }}>
              {"내가 찾는 분양권 매물이 없다면?"}
            </span>
            <button
              style={{
                backgroundColor: colorStyles.main,
                color: "white",
                borderRadius: "8px",
                border: "none",
                width: "150px",
                height: "31px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={handlePreBuyButtonClick}
            >
              {"매수 예약하기"}
            </button>
          </div>
          <section
            className="InfoClass"
            style={{
              marginTop: "20px",
            }}
          >
            <span style={{ fontSize: "16px", fontWeight: 700 }}>기본정보</span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                gap: "8px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div style={{ display: "flex" }}>
                <span style={{ color: colorStyles.gray, flex: 1 }}>
                  주택 유형
                </span>
                <span style={{ flex: 1 }}>
                  {resultData.house_secd_nm === "APT"
                    ? "아파트"
                    : resultData.house_secd_nm}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    flex: 1,
                    color: colorStyles.gray,
                    alignSelf: "center",
                  }}
                >
                  주소
                </span>
                <span style={{ flex: 1 }}>{resultData.new_hssply_adres}</span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ color: colorStyles.gray, flex: 1 }}>
                  입주시기
                </span>
                <span style={{ flex: 1 }}>
                  {formatYearMonth(resultData.mvn_prearnge_ym) || "미정"}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ color: colorStyles.gray, flex: 1 }}>
                  전매제한
                </span>
                <span style={{ flex: 1 }}>
                  {getRestrictionResale(resultData)}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ color: colorStyles.gray, flex: 1 }}>세대수</span>
                <span style={{ flex: 1 }}>
                  {resultData.tot_hshld?.toLocaleString()}
                </span>
              </div>
            </div>
          </section>
          <HorizontalLine size={4} color={"#D9D9D9"} />
          <section
            className="typeClass"
            style={{ marginTop: "20px", marginBottom: "20px" }}
          >
            <span style={{ fontSize: "16px", fontWeight: 700 }}>
              타입별 경쟁률
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                gap: "8px",
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  color: colorStyles.gray,
                }}
              >
                <span style={{ width: "25%", textAlign: "start" }}>{`전용(${
                  unitState === "pyeong" ? "평" : "m²"
                })`}</span>
                <span style={{ width: "25%", textAlign: "center" }}>
                  분양세대
                </span>
                <span style={{ width: "25%", textAlign: "center" }}>
                  분양가
                </span>
                <span style={{ width: "25%", textAlign: "end" }}>
                  분양경쟁률
                </span>
              </div>
              {resultData.details &&
              resultData.details.some((item) => item.model_no) ? (
                resultData.details.map((item, index) => (
                  <div
                    key={`${index}-${item.id}`}
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <span style={{ width: "25%" }}>
                      {unitState === "pyeong"
                        ? `${squareMeterToPyeong(
                            getExclusiveArea(item.house_type)
                          )}${initHouseOnlyType(item.house_type)}`
                        : `${getExclusiveArea(
                            item.house_type
                          )}${initHouseOnlyType(item.house_type)}`}
                    </span>
                    <span style={{ width: "25%", textAlign: "center" }}>
                      {item.general_supply_households}
                    </span>
                    <span style={{ width: "25%", textAlign: "center" }}>
                      {unitState === "pyeong"
                        ? `${calculateUnitPrice(
                            Number(item.house_supply_area),
                            Number(item.max_supply_price)
                          ).pricePerPyeong.toLocaleString("ko-KR")}만원`
                        : `${calculateUnitPrice(
                            Number(item.house_supply_area),
                            Number(item.max_supply_price)
                          ).pricePerSquareMeter.toLocaleString("ko-KR")}만원`}
                    </span>
                    <span style={{ width: "25%", textAlign: "end" }}>
                      {item.lcl_cmp_rate
                        ? item.lcl_cmp_rate < 0
                          ? "미분양"
                          : `${item.lcl_cmp_rate}:1`
                        : "미분양"}
                    </span>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100px",
                    marginTop: "10px",
                    padding: "16px 0",
                    backgroundColor: colorStyles.light_gray,
                    gap: "16px",
                  }}
                >
                  <span style={{ fontSize: "16px", fontWeight: 700 }}>
                    {"아직 등록된 정보가 없습니다"}
                  </span>
                </div>
              )}
            </div>
          </section>
          <HorizontalLine size={4} color={"#D9D9D9"} />
          <div style={{ marginTop: "20px" }}>
            <span style={{ fontSize: "16px", fontWeight: 700 }}>
              주변 분양 단지
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              gap: "8px",
            }}
          >
            {resultData.details?.length > 0 ? (
              <AroundInfo
                unitState={unitState}
                data={aroundData}
                handleAroundClick={handleAroundClick}
              />
            ) : (
              <div>정보없음</div>
            )}
          </div>
        </section>
      );
      //분양전
    } else {
      return (
        <section>
          <section
            className="InfoClass"
            style={{
              marginTop: "20px",
            }}
          >
            <span style={{ fontSize: "16px", fontWeight: 700 }}>기본정보</span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                gap: "8px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div style={{ display: "flex" }}>
                <span style={{ color: colorStyles.gray, flex: 1 }}>
                  주택 유형
                </span>
                <span style={{ flex: 1 }}>
                  {resultData.house_secd_nm === "APT"
                    ? "아파트"
                    : resultData.house_secd_nm}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span
                  style={{
                    flex: 1,
                    color: colorStyles.gray,
                    alignSelf: "center",
                  }}
                >
                  주소
                </span>
                <span style={{ flex: 1 }}>{resultData.new_hssply_adres}</span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ color: colorStyles.gray, flex: 1 }}>
                  입주시기
                </span>
                <span style={{ flex: 1 }}>
                  {formatYearMonth(resultData.mvn_prearnge_ym) || "미정"}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ color: colorStyles.gray, flex: 1 }}>
                  전매제한
                </span>
                <span style={{ flex: 1 }}>
                  {getRestrictionResale(resultData)}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ color: colorStyles.gray, flex: 1 }}>세대수</span>
                <span style={{ flex: 1 }}>
                  {resultData.tot_hshld?.toLocaleString()}
                </span>
              </div>
            </div>
          </section>
          <section
            className="InfoClass"
            style={{
              marginTop: "20px",
            }}
          >
            <span style={{ fontSize: "16px", fontWeight: 700 }}>접수일정</span>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              <div style={{ display: "flex" }}>
                <span style={{ color: colorStyles.gray, flex: "1" }}>
                  모집공고일
                </span>
                <span style={{ flex: "1" }}>
                  {initDate(resultData.rcrit_pblanc_de)}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ color: colorStyles.gray, flex: "1" }}>
                  특별공급 접수일
                </span>
                <span style={{ flex: "1" }}>
                  {initDate(resultData.spsply_rcept_bgnde)}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ color: colorStyles.gray, flex: "1" }}>
                  1순위 접수일
                </span>
                <span style={{ flex: "1" }}>
                  {initDate(resultData.gnrl_rnk1_crsparea_rcptde)}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ color: colorStyles.gray, flex: "1" }}>
                  2순위 접수일
                </span>
                <span style={{ flex: "1" }}>
                  {initDate(resultData.gnrl_rnk2_crsparea_rcptde)}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ color: colorStyles.gray, flex: "1" }}>
                  당첨자 발표일
                </span>
                <span style={{ flex: "1" }}>
                  {initDate(resultData.przwner_presnatn_de)}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ color: colorStyles.gray, flex: "1" }}>
                  계약일
                </span>
                <span style={{ flex: "1" }}>
                  {initDate(
                    resultData.cntrct_cncls_bgnde,
                    resultData.cntrct_cncls_endde
                  )}
                </span>
              </div>
            </div>
          </section>
          <section className="priceClass" style={{ marginTop: "20px" }}>
            <span style={{ fontSize: "16px", fontWeight: 700 }}>
              최고 분양가
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                gap: "8px",
                marginTop: "10px",
              }}
            >
              {resultData.details &&
              resultData.details.some((item) => item.model_no) ? (
                resultData.details.map((item, index) => (
                  <div key={`${index}-${item.id}`} style={{ display: "flex" }}>
                    <span style={{ color: colorStyles.gray, flex: 1 }}>
                      {unitState === "pyeong"
                        ? `${squareMeterToPyeong(
                            getExclusiveArea(item.house_type)
                          )}${initHouseOnlyType(item.house_type)}`
                        : `${getExclusiveArea(
                            item.house_type
                          )}${initHouseOnlyType(item.house_type)}`}
                    </span>
                    <span style={{ flex: 1 }}>
                      {`${(Number(item.max_supply_price) / 10000)
                        .toFixed(1)
                        .toLocaleString("ko-KR")}억`}
                    </span>
                  </div>
                ))
              ) : (
                <div style={{ textAlign: "center" }}>정보없음</div>
              )}
            </div>
          </section>
          <div style={{ marginTop: "20px" }}>
            <span style={{ fontSize: "16px", fontWeight: 700 }}>
              주변 분양 단지
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "12px",
              gap: "8px",
            }}
          >
            {resultData.details?.length > 0 ? (
              <AroundInfo
                data={aroundData}
                handleAroundClick={handleAroundClick}
              />
            ) : (
              <div>정보없음</div>
            )}
          </div>
        </section>
      );
    }
  };

  return (
    <>
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          width: screenStyles.width,
          height: "calc(var(--vh, 1vh) * 100)",
          backgroundColor: "white",
          position: "fixed",
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
      >
        {showSellPanel ? (
          <SellRealEstatePanel
            data={resultData}
            presaleData={preSaleData}
            mapService={mapService}
            setShowSellPanel={setShowSellPanel}
          />
        ) : showBuyPanel ? (
          <BuyRealEstatePanel
            data={preSaleData}
            mapService={mapService}
            setShowBuyPanel={setShowBuyPanel}
          />
        ) : (
          <>
            {!isLoading ? (
              <>
                <section
                  className="header"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: screenStyles.width,
                    height: "47px",
                    backgroundColor: "white",
                    borderBottom: `1px ${colorStyles.dark_gray} solid`,
                  }}
                >
                  <div
                    className="logo"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleBackBtn}
                  >
                    <FaChevronLeft
                      style={{
                        width: "15px",
                        height: "15px",
                        paddingLeft: "10px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "50px",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <SlRefresh
                      size={30}
                      style={{
                        transform: `rotate(100deg)`,
                        marginRight: unitState === "pyeong" ? "-20px" : "-22px", // '평'과 겹치게 하기 위해 음수 마진 사용
                      }}
                      onClick={changeUnit}
                    />
                    <span style={{ fontSize: 12, fontWeight: 700 }}>
                      {unitState === "pyeong" ? "평" : "m²"}
                    </span>
                  </div>
                </section>
                <section
                  className="title"
                  style={{ padding: "26px 13px 0px 13px", marginBottom: "5px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: 400,
                        color: colorStyles.gray,
                      }}
                    >
                      {saleState === "future-contract"
                        ? "분양전"
                        : saleState === "past-contract"
                        ? "분양완료"
                        : "분양중"}
                    </span>
                    <span style={{ fontSize: "24px", fontWeight: 700 }}>
                      {resultData.new_house_nm}
                    </span>
                  </div>
                  <div
                    className="linkSection"
                    style={{ display: "flex", marginTop: "10px", gap: "10px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenUrl(resultData.hmpg_adres)}
                    >
                      <FiExternalLink
                        color={colorStyles.main}
                        size={22}
                        strokeWidth={1.5}
                      />
                      <span
                        style={{
                          fontSize: "12px",
                          color: colorStyles.gray,
                        }}
                      >
                        홈페이지
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenUrl(resultData.pblanc_url)}
                    >
                      <FiExternalLink
                        color={colorStyles.main}
                        size={22}
                        strokeWidth={1.5}
                      />
                      <span
                        style={{
                          fontSize: "12px",
                          color: colorStyles.gray,
                        }}
                      >
                        청약홈
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <PiDownloadSimple
                        color={colorStyles.main}
                        size={22}
                        strokeWidth={1}
                      />
                      <span
                        style={{
                          fontSize: "12px",
                          color: colorStyles.gray,
                        }}
                      >
                        입주자 모집공고
                      </span>
                    </div>
                  </div>
                </section>
                <section
                  ref={mainRef}
                  className="main"
                  style={{
                    padding: "0 13px",
                    overflowY: "scroll", // 세로 스크롤만 활성화
                    scrollbarWidth: "none", // Firefox에서 스크롤 바 숨기기
                    msOverflowStyle: "none", // IE 및 Edge에서 스크롤 바 숨기기
                    "&::WebkitScrollbar": { display: "none" },
                    flex: 9,
                  }}
                >
                  {renderContent()}
                </section>
              </>
            ) : (
              <></>
            )}

            <SellRealEstateButton
              onClick={() => setPreSaleData([])}
              handleClick={setShowSellPanel}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DetailPage;
