import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";
import { useEffect, useState } from "react";

const StepOptions = ({ data, onDataChange, setIsNextEnabled }) => {
  const colorStyles = useRecoilValue(themeColorsState);

  const [wordInputValue, setWordInputValue] = useState("");
  const [inputWords, setInputWords] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      setWordInputValue(data.join(","));
      setInputWords(data);
    }
  }, []);

  //항상 '다음'버튼 활성화
  useEffect(() => {
    setIsNextEnabled(true);
  });

  const handleWordInputChange = (e) => {
    const value = e.target.value;
    setWordInputValue(value);

    const words = value
      .split(",")
      .map((word) => word.trim())
      .filter((word) => word !== "");

    // Remove duplicates
    const uniqueWords = [...new Set(words)];

    setInputWords(uniqueWords);

    setIsNextEnabled(uniqueWords.length > 0); // Enable 'Next' only if there is at least one word
  };

  const handleRemoveWord = (index) => {
    const newWords = inputWords.filter((_, i) => i !== index);
    setInputWords(newWords);
    setWordInputValue(newWords.join(", ")); // Sync input field with the updated word list
    setIsNextEnabled(newWords.length > 0);
    onDataChange("options", newWords);
  };

  const handleBlurEvent = () => {
    onDataChange("options", inputWords);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "20px 10px",
        gap: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
          gap: "8px",
        }}
      >
        <span style={{ fontSize: "22px", fontWeight: 600 }}>
          옵션 및 특이사항을 입력해주세요
        </span>
        <span style={{ fontSize: "16px", color: colorStyles.gray }}>
          {",(콤마)로 구분되어 입력됩니다."}
        </span>
      </div>

      <input
        type="text"
        placeholder="남향, 시스템 에어컨, 팬트리, 공원뷰, ... 등"
        value={wordInputValue}
        onChange={handleWordInputChange}
        onBlur={handleBlurEvent}
        maxLength={100}
        style={{
          height: "60px",
          borderRadius: "5px",
          fontSize: "16px",
          paddingLeft: "5px",
        }}
      />
      <div style={{ marginTop: "10px" }}>
        {inputWords.map((word, index) => (
          <div
            key={index}
            style={{
              display: "inline-block",
              width: "max-content",
              margin: "5px",
              padding: "10px",
              border: "1px solid #ccc",
              position: "relative",
              borderRadius: "5px",
              fontSize: "12px",
              fontWeight: 600,
              color: colorStyles.main,
              backgroundColor: colorStyles.dark_gray,
            }}
          >
            {word}
            <span
              onClick={() => handleRemoveWord(index)}
              style={{
                cursor: "pointer",
                fontSize: "15px",
                marginLeft: "5px",
                color: colorStyles.main,
              }}
            >
              x
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepOptions;
