export default class ContentService {
  constructor(http) {
    this.http = http;
  }

  async getNews(offset) {
    return await this.http.fetch(`/news?offset=${offset}`, {
      method: "GET",
    });
  }

  async getSubNotice(obj) {
    return await this.http.fetch("/subscription/", {
      method: "GET",
    });
  }

  async getSuggestedSearchTerms(search_term) {
    return await this.http.fetch(`/search/suggest?term=${search_term}`, {
      method: "GET",
    });
  }

  async getRecentContentData(offset) {
    return await this.http.fetch(`/content/recent?offset=${offset}`, {
      method: "GET",
    });
  }
}
