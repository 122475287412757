import { useEffect, useState } from "react";
import HorizontalLine from "../seperators/horizontal_line";
import styles from "./house_type_section.module.css";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";

const HouseTypeSection = ({ initData, handleOptionSingleClick, isReset }) => {
  const colorStyles = useRecoilValue(themeColorsState);
  const houseTypes = ["주택", "상가/업무"];
  const [selectedButton, setSelectedButton] = useState("");
  useEffect(() => {
    initData && setSelectedButton(initData);
  }, [initData]);

  const handleButtonClick = (newOption) => {
    newOption === selectedButton
      ? setSelectedButton("")
      : setSelectedButton(newOption);
  };

  const handleReset = () => {
    setSelectedButton("");
  };

  useEffect(() => {
    handleOptionSingleClick("건물종류", selectedButton);
  }, [selectedButton]);

  useEffect(() => {
    isReset && handleReset();
  }, [isReset]);

  return (
    <>
      <h4 className={styles.categoryTitle}>{"건물 종류"}</h4>
      <HorizontalLine size={1} color={colorStyles.dark_gray} />
      <div className={styles.categoryOptions}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            fontWeight: 600,
          }}
        >
          <span>{"건물 종류"}</span>
        </div>
        <HorizontalLine size={1} color={colorStyles.dark_gray} />
        <div
          className={styles.buttonSection}
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: "20px",
            gap: "10px",
          }}
        >
          {houseTypes.map((option) => (
            <button
              key={option}
              onClick={() => handleButtonClick(option)}
              className={styles.optionButton}
              style={{
                color: selectedButton === option ? colorStyles.main : "",
                borderColor: selectedButton === option ? colorStyles.main : "",
                fontWeight: selectedButton === option ? "bold" : "",
                borderWidth: selectedButton === option ? "2px" : "",
              }}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default HouseTypeSection;
