import { atom } from "recoil";

export const themeColorsState = atom({
  key: "themeColorsState",
  default: {
    gray: "#79747E",
    light_gray: "#F5F5F5",
    dark_gray: "#D9D9D9",
    yellow: "#FFCC00",
    red: "#E90C00",
    white: "#FFFFFF",
    black: "#000000",
    main: "#00A0FA",
  },
});
