import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { screenStylesState } from "../../stores/screen_styles_state";
import { themeColorsState } from "../../stores/theme_colors_state";

const CustomProgressBar = ({ step, totalSteps }) => {
  const screenStyles = useRecoilValue(screenStylesState);
  const colorStyles = useRecoilValue(themeColorsState);
  const containerStyles = {
    height: 3,
    width: "100%",
    backgroundColor: `${colorStyles.dark_gray}`,
    // borderRadius: 50,
    overflow: "hidden",
  };

  const progress = (step / totalSteps) * 100;

  const fillerStyles = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: `${colorStyles.main}`,
    transition: "width 0.5s ease-in-out",
    borderRadius: "inherit",
    textAlign: "center",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        {/* <span style={labelStyles}>{`${progress}%`}</span> */}
      </div>
    </div>
  );
};

export default CustomProgressBar;
