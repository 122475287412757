import React, { useState, useEffect } from "react";
import styles from "./search_rank.module.css";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";

const SearchRank = () => {
  const colorStyles = useRecoilValue(themeColorsState);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Example search terms. Replace with dynamic data as needed.
  const searchTerms = [
    "수리산성원상떼빌1차아파트",
    "안양동성원아파트",
    "안양씨엘포레자이아파트",
    "안양어반포레자연앤e편한세상아파트",
    "안양동우성1차아파트",
    "안양동우성2차아파트",
    "프라자아파트",
    "한신아파트",
    "SG타워",
    "수리산성원상떼빌2차아파트",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % searchTerms.length);
    }, 5000); // Change every 3 seconds

    return () => clearInterval(interval);
  }, [searchTerms.length]);

  return (
    <section style={{ display: "flex", margin: "10px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          textWrap: "nowrap",
          fontSize: "12px",
          fontWeight: 600,
          color: colorStyles.gray,
        }}
      >
        실시간
      </div>
      <div className={styles.container}>
        <div key={currentIndex} className={styles.searchTerm}>
          <div
            style={{
              fontSize: "22px",
              color: colorStyles.main,
            }}
          >
            {currentIndex + 1}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "90%",
              fontSize: "16px",
              textWrap: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {searchTerms[currentIndex]}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchRank;
