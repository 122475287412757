import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { screenStylesState } from "../stores/screen_styles_state";
import { themeColorsState } from "../stores/theme_colors_state";
import SearchBar from "../components/search_bar/search_bar";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryButtonBar from "../components/category_button_bar/category_button_bar";
import Map from "../components/map/map";
import { FiHome } from "react-icons/fi";
import { FaChevronLeft } from "react-icons/fa6";
import { PiWarningOctagonLight } from "react-icons/pi";
import SellRealEstatePanel from "../components/sell_real_estate_panel/sell_real_estate_panel";

const SearchResultPage = ({ mapService, contentService }) => {
  const screenStyles = useRecoilValue(screenStylesState);
  const colorStyles = useRecoilValue(themeColorsState);

  const navigation = useNavigate();

  const [initOptions, setInitOptions] = useState({
    지역: [],
    가격: [0, 5000000000],
    가격타입: "분양가",
    건물종류: [],
    면적: [0, 50],
    세대수: [0, 5000],
    주택수: null,
  });

  const param = useLocation();
  const queryParams = new URLSearchParams(param.search);

  const [locationValue, setLocationValue] = useState("");
  const [filterValue, setFilterValue] = useState(initOptions);

  const [paramType, setParamType] = useState("");
  const [changedComplete, setChangedComplete] = useState(false);
  // const value = queryParams.get("term") || queryParams.get("filter");
  // const ts = queryParams.get("_ts") || null;

  const [isLoading, setIsLoading] = useState(true);

  const [resultData, setResultData] = useState([]);
  const [currentZoom, setCurrentZoom] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);

  const [showCategoryOptions, setShowCategoryOptions] = useState(false);
  const [showSellPanel, setShowSellPanel] = useState(false);

  //새로고침했을 경우, Map데이터 초기화
  useEffect(() => {
    const storedResultData = sessionStorage.getItem("resultData");
    if (storedResultData) {
      setResultData(JSON.parse(storedResultData));
    }

    setLocationValue(queryParams.get("term") || "");
    setFilterValue(
      JSON.parse(decodeURIComponent(queryParams.get("filter"))) || initOptions
    );
  }, []);

  //URL의 변화를 감지해서 검색타입 확인
  useEffect(() => {
    const term = queryParams.get("term");
    const filter = queryParams.get("filter");

    if (term) {
      setParamType("term");
      setLocationValue(term);
      setFilterValue(initOptions); // 필터 초기화
    } else if (filter) {
      setParamType("filter");
      setLocationValue("");
      setFilterValue(JSON.parse(decodeURIComponent(filter)));
    } else {
      alert("잘못된 접근입니다.");
    }
    // console.log("검색타입 바뀜");
  }, [param.search]);

  //검색타입에 맞춰서 서버와 통신
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let res;

      if (paramType === "term" && locationValue) {
        res = await mapService.getSearchInfo(locationValue);
      } else if (paramType === "filter") {
        res = await mapService.getAreaInfo(filterValue);
      }

      setResultData(res?.result || []);
      // console.log(res?.result);

      sessionStorage.setItem("resultData", JSON.stringify(res.result));
      setIsLoading(false);
      setShowCategoryOptions(false); //필터검색하면 Detail창이 안나와서
    };

    paramType && fetchData();

    // console.log("fetch!");
    // console.log(resultData);
    // console.log("로딩상태: ", isLoading);
  }, [paramType, locationValue, filterValue]);

  useEffect(() => {
    if (currentZoom > 14) {
      setZoomLevel("lvl0");
    } else if (currentZoom === 13 || currentZoom === 14) {
      setZoomLevel("lvl1");
    } else if (currentZoom === 11 || currentZoom === 12) {
      setZoomLevel("lvl2");
    } else {
      setZoomLevel("lvl3");
    }
  }, [currentZoom]);

  const onFilterSearch = (options) => {
    const optionsString = encodeURIComponent(JSON.stringify(options));
    const timestamp = new Date().getTime();
    navigation(`/search?filter=${optionsString}&_ts=${timestamp}`);
  };

  const onTermSearch = (inputText) => {
    if (inputText) {
      // console.log(inputText);
      const timestamp = new Date().getTime();
      navigation(`/search?term=${inputText}&_ts=${timestamp}`);
    }
  };

  const handleBackBtn = () => {
    // navigation(-1);
    navigation("/", { replace: true });
  };

  if (isLoading) {
    return <>로딩중...</>;
  }

  // if (resultData.length === 0) {
  //   return <>데이터가 없습니다.</>;
  // }

  return (
    <div
      className="container"
      style={{
        display: "flex",
        flexDirection: "column",
        width: screenStyles.width,
        height: "calc(var(--vh, 1vh) * 100)",
        backgroundColor: "white",
        position: "fixed",
        left: "50%",
        transform: "translate(-50%, 0)",
      }}
    >
      {showSellPanel ? (
        <>
          <SellRealEstatePanel
            mapService={mapService}
            setShowSellPanel={setShowSellPanel}
          />
        </>
      ) : (
        <>
          <section
            className="header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: screenStyles.width,
              height: "47px",
              backgroundColor: "white",
              borderBottom: `1px ${colorStyles.dark_gray} solid`,
            }}
          >
            <div
              className="logo"
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleBackBtn}
            >
              <FaChevronLeft
                style={{ width: "24px", height: "24px", paddingLeft: "10px" }}
              />
              <FiHome
                style={{
                  width: "24px",
                  height: "24px",
                  padding: "4px",
                  strokeWidth: 1.5,
                }}
              />
            </div>
          </section>
          <section className="main">
            <div className="controlBox" style={{ flex: 1 }}>
              {!isLoading && (
                <>
                  <SearchBar
                    placeHolder={locationValue}
                    onSearch={onTermSearch}
                    contentService={contentService}
                  />
                  <CategoryButtonBar
                    categories={filterValue}
                    onFilterSearch={onFilterSearch}
                    setShowCategoryOptions={setShowCategoryOptions}
                  />
                </>
              )}
            </div>
            <div
              className="mapBox"
              style={{
                width: "100%",
                flex: 9,
              }}
            >
              <div
                style={{
                  height: "80vh",
                }}
              >
                <>
                  {!isLoading && resultData.aptInfo?.length > 0 ? (
                    <Map
                      resultData={resultData}
                      handleCurrentZoom={setCurrentZoom}
                      zoomLevel={zoomLevel}
                      searchType={paramType}
                      mapService={mapService}
                      showCategoryOptions={showCategoryOptions}
                      setShowSellPanel={setShowSellPanel}
                    />
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <PiWarningOctagonLight
                          style={{
                            width: "55px",
                            height: "55px",
                            strokeWidth: 1,
                          }}
                        />
                        <h4>검색 결과가 존재하지 않습니다.</h4>
                        <h4>검색어 혹은 필터를 변경해서 다시 검색해주세요.</h4>
                      </div>
                    </>
                  )}
                </>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default SearchResultPage;
