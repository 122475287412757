import React, { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import styles from "./cost_slider.module.css";
import HorizontalLine from "../seperators/horizontal_line";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";

const CostSlider = ({
  initData,
  initValues,
  handleOptionSingleClick,
  handlePriceChange,
  isReset,
}) => {
  const colorStyles = useRecoilValue(themeColorsState);
  const [optionTitle, setOptionTitle] = useState("분양가");
  const [selectedButton, setSelectedButton] = useState("분양가"); // 클릭된 버튼을 추적
  const [inputValues, setInputValues] = useState([0, 5000000000]); // input 필드 값 관리
  const buttonCategories = ["분양가", "계약금", "프리미엄"];
  useEffect(() => {
    if (initData) {
      setOptionTitle(initData);
      setSelectedButton(initData);
    }
    initValues && setInputValues(initValues);
  }, [initData, initValues]);

  const formatPriceLabel = (value) => `${(value / 100000000).toFixed(1)}억`;

  const handleButtonClick = (newTitle) => {
    setOptionTitle(newTitle);
    setSelectedButton(newTitle); // 클릭된 버튼 업데이트
  };

  useEffect(() => {
    handleOptionSingleClick("가격타입", optionTitle);
    handleOptionSingleClick("가격", inputValues);
  }, [optionTitle, inputValues]);

  const handleSliderChange = (event, newValue) => {
    handlePriceChange("가격", newValue);
    setInputValues(newValue); // 스크롤이 바뀔 때 input 필드 업데이트
  };

  const handleInputChange = (index, value) => {
    const newValues = [...inputValues];
    newValues[index] = value;

    if (value >= 0 && value <= 5000000000) {
      setInputValues(newValues);
      handlePriceChange("가격", newValues); // input 필드가 바뀔 때 스크롤 업데이트
    }
  };

  const handleReset = () => {
    const initialPriceRange = [0, 5000000000];
    setInputValues(initialPriceRange);
    handlePriceChange("가격", initialPriceRange);
    setSelectedButton("분양가");
    setOptionTitle("분양가");
  };

  useEffect(() => {
    isReset && handleReset();
  }, [isReset]);

  return (
    <section className={styles.categorySection}>
      <h4 className={styles.categoryTitle}>{"가격"}</h4>
      <HorizontalLine size={1} color={colorStyles.dark_gray} />
      <div className={styles.categoryOptions}>
        <div
          style={{
            display: "flex",
            padding: "10px",
            gap: "10px",
            fontWeight: 600,
          }}
        >
          <span className={styles.optionTitle}>{optionTitle}</span>
          <span>
            {inputValues[0] === 0 ? "최소" : formatPriceLabel(inputValues[0])}
          </span>
          <span>{"~"}</span>
          <span>
            {inputValues[1] === 5000000000
              ? "최대"
              : formatPriceLabel(inputValues[1])}
          </span>
        </div>
        <HorizontalLine size={1} color={colorStyles.dark_gray} />
        <div className={styles.buttonContainer}>
          {buttonCategories.map((title) => (
            <button
              key={title}
              className={styles.optionButton}
              onClick={() => handleButtonClick(title)}
              style={{
                color: selectedButton === title ? colorStyles.main : "",
                borderColor: selectedButton === title ? colorStyles.main : "",
                fontWeight: selectedButton === title ? "bold" : "",
                borderWidth: selectedButton === title ? "2px" : "",
              }}
            >
              {title}
            </button>
          ))}
        </div>
        <div className={styles.rangeSliderContainer}>
          <Slider
            value={inputValues || [0, 5000000000]}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            sx={{
              width: "80%",
              color: colorStyles.main,
              "& .MuiSlider-thumb": { color: "lightgray" },
              "& .MuiSlider-markLabel": { color: "#49454f" },
              "& .MuiSlider-track": { height: "1px" },
            }}
            max={5000000000}
            step={1000000}
            disableSwap
            marks={[
              { value: 0, label: "0억" },
              { value: 500000000, label: "5억" },
              { value: 1000000000, label: "10억" },
              { value: 2000000000, label: "20억" },
              { value: 3000000000, label: "30억" },
              { value: 4000000000, label: "40억" },
              { value: 5000000000, label: "50억" },
            ]}
            valueLabelFormat={formatPriceLabel}
          />
        </div>
        <div className={styles.inputSection}>
          <div
            className="leftInput"
            style={{
              marginRight: "20px",
            }}
          >
            <input
              type="number"
              value={inputValues[0] / 10000 || 0}
              onChange={(e) =>
                handleInputChange(
                  0,
                  Math.min(Number(e.target.value), inputValues[1])
                )
              }
              style={{
                width: "80px",
                height: "40px",
                borderRadius: "10px",
                border: `1px solid ${colorStyles.dark_gray}`,
                paddingLeft: "5px",
                marginRight: "4px",
                color: "#49454f",
                backgroundColor: "#f5f5f5",
              }}
              placeholder="최소금액"
            />
            <span>{"만원"}</span>
          </div>
          <div style={{ width: "31px" }}>
            <HorizontalLine size={1} color={"lightgray"} />
          </div>
          <div className="rightInput">
            <input
              type="number"
              value={inputValues[1] / 10000 || 5000000000}
              onChange={(e) =>
                handleInputChange(
                  1,
                  Math.max(Number(e.target.value), inputValues[0])
                )
              }
              style={{
                width: "80px",
                height: "40px",
                borderRadius: "10px",
                border: `1px solid ${colorStyles.dark_gray}`,
                paddingLeft: "5px",
                marginLeft: "20px",
                marginRight: "4px",
                color: "#49454f",
                backgroundColor: "#f5f5f5",
              }}
              placeholder="최대금액"
            />
            <span>{"만원"}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CostSlider;
