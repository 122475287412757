import React, { useEffect, useState } from "react";
import styles from "./custom_select.module.css";
import { MdExpandMore } from "react-icons/md";

const CustomSelect = ({
  placeholder,
  options,
  onChange,
  inputValue = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    // console.log(inputValue);
    if (inputValue) {
      setSelectedOption(inputValue);
    }
  }, []);

  const handleOptionClick = (option) => {
    // console.log(option);

    setSelectedOption(option);
    setIsOpen(false);
    onChange(option);
  };

  return (
    <div className={styles.selectContainer} onClick={toggleDropdown}>
      <div className={styles.selectBox}>
        <div className={styles.selectButton}>
          {selectedOption?.name ? selectedOption.name : placeholder}
        </div>
        <MdExpandMore size={40} color={"#1E1E1E"} />
      </div>

      {isOpen && (
        <ul className={styles.optionsList}>
          {options.map((option) => (
            <li
              key={option.id}
              onClick={() => handleOptionClick(option)}
              className={styles.option}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
