import { useEffect, useRef, useState } from "react";
import styles from "./custom_carousel_small.module.css";

const CustomCarouselSmall = ({ subList }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isCarouselDragging, setIsCarouselDragging] = useState(false);
  const carouselRef = useRef(null);
  const carouselStartX = useRef(null);

  const itemCount = 1;
  const totalSlides = Math.ceil(subList.length / (itemCount * 2));

  const handleCarouselDragStart = (e) => {
    carouselStartX.current = e.pageX - carouselRef.current.offsetLeft;
    setIsCarouselDragging(true);
  };

  const handleCarouselDragMove = (e) => {
    if (!isCarouselDragging) return;
    e.preventDefault();
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - carouselStartX.current) * 1;
    if (walk < -50) {
      handleNextSlide();
      setIsCarouselDragging(false);
    } else if (walk > 50) {
      handlePrevSlide();
      setIsCarouselDragging(false);
    }
  };

  const handleCarouselDragEnd = () => {
    setIsCarouselDragging(false);
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
  };

  const renderCarouselItems = () => {
    const items = [];
    const directionMap = {
      east: "동",
      west: "서",
      south: "남",
      north: "북",
    };
    const initHouseType = (input) => {
      const typeString = input.match(/[a-zA-Z]+/g);
      const typeArea = parseInt(input.split(".")[0]);
      return typeString ? `${typeArea}${typeString}` : `${typeArea}`;
    };
    const initPrice = (input) => {
      // const price = (Number(input) / 10000).toFixed(1);
      const price = Number(input).toLocaleString();
      return `${price}만원`;
    };
    for (let i = 0; i < subList.length; i += itemCount) {
      items.push(
        <div key={i} className={styles.carouselSlide}>
          {subList.slice(i, i + itemCount).map((subItem, index) =>
            subItem.trade_type === "sell" ? (
              <div key={index} className={styles.subItem}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "150px",
                    height: "64px",
                    gap: "4px",
                  }}
                >
                  <div className={styles.subItemTitle}>
                    <span
                      style={{
                        width: "32px",
                        height: "20px",
                        textAlign: "center",
                        color: "#0274B4",
                        border: "1.5px solid #0274B4",
                        fontSize: "12px",
                        fontWeight: "bold",
                        margin: "4px 4px 8px 0",
                        padding: "2px",
                      }}
                    >
                      매도
                    </span>
                    <span>P {initPrice(subItem.hope_price)}</span>
                  </div>
                  <span>
                    {`${
                      subItem.house_type
                        ? initHouseType(subItem.house_type)
                        : subItem.house_type
                    } | ${subItem.dong}동 ${Math.floor(subItem.ho / 100)}층`}
                  </span>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "180px",
                    }}
                  >
                    {subItem.options.join(", ")}
                  </div>
                </div>
              </div>
            ) : (
              <div key={index} className={styles.subItem}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "150px",
                    height: "64px",
                    gap: "4px",
                  }}
                >
                  <div className={styles.subItemTitle}>
                    <span
                      style={{
                        width: "32px",
                        height: "20px",
                        textAlign: "center",
                        color: "#0274B4",
                        border: "1.5px solid #0274B4",
                        fontSize: "12px",
                        fontWeight: "bold",
                        margin: "4px 4px 8px 0",
                        padding: "2px",
                      }}
                    >
                      매수
                    </span>
                    <span>P {initPrice(subItem.hope_price)}</span>
                  </div>
                  <span>
                    {`${
                      subItem.house_type
                        ? initHouseType(subItem.house_type)
                        : "정보없음"
                    } | ${subItem.dong}동 ${subItem.floor}층`}
                  </span>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "180px",
                      height: "14px",
                    }}
                  >
                    {subItem.options?.join(", ")}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      );
    }
    return items;
  };

  return (
    <div
      className={styles.carouselContainer}
      ref={carouselRef}
      onMouseDown={handleCarouselDragStart}
      onMouseMove={handleCarouselDragMove}
      onMouseUp={handleCarouselDragEnd}
      onMouseLeave={handleCarouselDragEnd}
    >
      <div
        className={styles.carouselWrapper}
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
          transition: isCarouselDragging
            ? "none"
            : "transform 0.3s ease-in-out",
        }}
      >
        {renderCarouselItems()}
      </div>
    </div>
  );
};

export default CustomCarouselSmall;
