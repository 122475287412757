import React, { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import styles from "./household_section.module.css";
import HorizontalLine from "../seperators/horizontal_line";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";

const HouseholdSection = ({
  initData,
  handleOptionSingleClick,
  handleNumberChange,
  isReset,
}) => {
  const colorStyles = useRecoilValue(themeColorsState);
  const initialNumberRange = [0, 5000];
  const [selectedButton, setSelectedButton] = useState(""); // 클릭된 버튼을 추적
  const [selectedTitle, setSelectedTitle] = useState("전체"); // 버튼에 '전체'가 없어서 따로 만들어 줌
  const [inputValues, setInputValues] = useState(initialNumberRange); // input 필드 값 관리
  const buttonCategories = [
    "100세대 이상",
    "500세대 이상",
    "1000세대 이상",
    "3000세대 이상",
    "5000세대 이상",
  ];
  useEffect(() => {
    if (initData) {
      setInputValues(initData);
      if (initData[0] === 100 && initData[1] === 5000) {
        setSelectedButton(buttonCategories[0]);
        setSelectedTitle(buttonCategories[0]);
      } else if (initData[0] === 500 && initData[1] === 5000) {
        setSelectedButton(buttonCategories[1]);
        setSelectedTitle(`${initData[0]}세대 ~ ${initData[1]}세대`);
      } else if (initData[0] === 1000 && initData[1] === 5000) {
        setSelectedButton(buttonCategories[2]);
        setSelectedTitle(`${initData[0]}세대 ~ ${initData[1]}세대`);
      } else if (initData[0] === 3000 && initData[1] === 5000) {
        setSelectedButton(buttonCategories[3]);
        setSelectedTitle(`${initData[0]}세대 ~ ${initData[1]}세대`);
      } else if (initData[0] === 5000 && initData[1] === 5000) {
        setSelectedButton(buttonCategories[4]);
        setSelectedTitle(`${initData[0]}세대 ~ ${initData[1]}세대`);
      } else {
        setSelectedTitle(`${initData[0]}세대 ~ ${initData[1]}세대`);
      }
    }
    // console.log("InitData", initData);
  }, [initData]);

  const handleButtonClick = (newTitle) => {
    if (newTitle === selectedButton) {
      setSelectedButton("");
      setSelectedTitle("전체");
      setInputValues(initialNumberRange);
    } else {
      setSelectedButton(newTitle); // 클릭된 버튼 업데이트
      // console.log(newTitle);
      setSelectedTitle(newTitle);
      if (newTitle === buttonCategories[0]) {
        setInputValues([100, 5000]);
      } else if (newTitle === buttonCategories[1]) {
        setInputValues([500, 5000]);
      } else if (newTitle === buttonCategories[2]) {
        setInputValues([1000, 5000]);
      } else if (newTitle === buttonCategories[3]) {
        setInputValues([3000, 5000]);
      } else if (newTitle === buttonCategories[4]) {
        setInputValues([5000, 5000]);
      }
    }
  };

  useEffect(() => {
    handleOptionSingleClick("세대수", inputValues);
  }, [inputValues]);

  const handleSliderChange = (event, newValue) => {
    handleNumberChange("세대수", newValue);
    setInputValues(newValue); // 스크롤이 바뀔 때 input 필드 업데이트
    if (newValue[0] === 100 && newValue[1] === 5000) {
      setSelectedButton(buttonCategories[0]);
      setSelectedTitle(buttonCategories[0]);
    } else if (newValue[0] === 500 && newValue[1] === 5000) {
      setSelectedButton(buttonCategories[1]);
      setSelectedTitle(buttonCategories[1]);
    } else if (newValue[0] === 1000 && newValue[1] === 5000) {
      setSelectedButton(buttonCategories[2]);
      setSelectedTitle(buttonCategories[2]);
    } else if (newValue[0] === 3000 && newValue[1] === 5000) {
      setSelectedButton(buttonCategories[3]);
      setSelectedTitle(buttonCategories[3]);
    } else if (newValue[0] === 5000 && newValue[1] === 5000) {
      setSelectedButton(buttonCategories[4]);
      setSelectedTitle(buttonCategories[4]);
    } else {
      setSelectedButton("");
      setSelectedTitle(`${newValue[0]}세대 ~ ${newValue[1]}세대`);
      // newValue[1] === 5000
      //   ? setSelectedTitle(`${newValue[0]}세대 이상`)
      //   : setSelectedTitle(`${newValue[0]}세대 ~ ${newValue[1]}세대`);
    }
  };

  const handleReset = () => {
    setInputValues(initialNumberRange);
    handleNumberChange("세대수", initialNumberRange);
    setSelectedButton("");
    setSelectedTitle("전체");
  };

  useEffect(() => {
    isReset && handleReset();
  }, [isReset]);

  return (
    <section className={styles.categorySection}>
      <h4 className={styles.categoryTitle}>{"세대수"}</h4>
      <HorizontalLine size={1} color={colorStyles.dark_gray} />
      <div className={styles.categoryOptions}>
        <div
          style={{
            display: "flex",
            padding: "10px",
            gap: "10px",
            fontWeight: 600,
          }}
        >
          <span>{selectedTitle}</span>
        </div>
        <HorizontalLine size={1} color={colorStyles.dark_gray} />

        <div className={styles.rangeSliderContainer}>
          <Slider
            value={inputValues || [0, 5000]}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            sx={{
              width: "80%",
              color: colorStyles.main,
              "& .MuiSlider-thumb": { color: "lightgray" },
              "& .MuiSlider-markLabel": { color: "#49454f" },
              "& .MuiSlider-track": { height: "1px" },
            }}
            max={5000}
            step={100}
            disableSwap
            marks={[
              { value: 0, label: "0" },
              { value: 100 },
              { value: 500 },
              { value: 1000, label: "1000" },
              { value: 3000, label: "3000" },
              { value: 5000, label: "5000" },
            ]}
          />
        </div>
        <div className={styles.buttonContainer}>
          {buttonCategories.map((title) => (
            <button
              key={title}
              className={styles.optionButton}
              onClick={() => handleButtonClick(title)}
              style={{
                textAlign: "center",
                color: selectedButton === title ? colorStyles.main : "",
                borderColor: selectedButton === title ? colorStyles.main : "",
                fontWeight: selectedButton === title ? "bold" : "",
                borderWidth: selectedButton === title ? "2px" : "",
              }}
            >
              {title}
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HouseholdSection;
