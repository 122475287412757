import React, { useState, useEffect } from "react";
import styles from "./region_category_section.module.css";
import HorizontalLine from "../seperators/horizontal_line";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";

const RegionCategorySection = ({
  initData,
  handleOptionSingleClick,
  isReset,
  active = true,
}) => {
  const colorStyles = useRecoilValue(themeColorsState);
  const [data, setData] = useState({});
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [districts, setDistricts] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectionList, setSelectionList] = useState([]); // 선택한 지역을 저장하는 리스트

  // JSON 파일을 불러옵니다.
  useEffect(() => {
    fetch("/regions.json")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching the JSON file:", error));
  }, []);

  useEffect(() => {
    if (initData) {
      if (initData.length === 1) {
        setSelectedCity(initData[0]);
        setSelectedDistrict("");
        setSelectedArea("");
        data &&
          selectedCity &&
          setDistricts(
            data[selectedCity].map((district) => Object.keys(district)[0])
          );
        setSelectionList([initData[0]]);
        setAreas([]);
      } else if (initData.length === 2) {
        setSelectedCity(initData[0]);
        setSelectedDistrict(initData[1]);
        setSelectedArea("");
        data &&
          selectedCity &&
          selectedDistrict &&
          setAreas(
            data[selectedCity].find((d) => d[selectedDistrict])[
              selectedDistrict
            ]
          );
        setSelectionList([initData[0], initData[1]]);
      } else {
        setSelectedCity(initData[0]);
        setSelectedDistrict(initData[1]);
        setSelectedArea(initData[2]);
        data &&
          selectedCity &&
          selectedDistrict &&
          setAreas(
            data[selectedCity].find((d) => d[selectedDistrict])[
              selectedDistrict
            ]
          );
        //체크안해주면 [undefined,undefined,undefined]가 들어간다
        initData[0] &&
          setSelectionList([initData[0], initData[1], initData[2]]);
      }
    }
  }, [initData, data]);

  useEffect(() => {
    isReset && handleReset();
  }, [isReset]);

  const hadleTitleClick = (level) => {
    if (level === "city") {
      setSelectedCity("");
      setSelectedDistrict("");
      setSelectedArea("");
      setSelectionList([]);
    } else {
      setSelectedDistrict("");
      setSelectedArea("");
      setSelectionList([selectedCity]);
      setDistricts(
        data[selectedCity].map((district) => Object.keys(district)[0])
      );
    }
  };

  const handleCityClick = (city) => {
    setSelectedCity(city);
    setSelectedDistrict("");
    setSelectedArea("");
    setDistricts(data[city].map((district) => Object.keys(district)[0]));
    setAreas([]); // Reset areas when changing cities

    // 선택 리스트 업데이트
    setSelectionList([city]); // 도시 선택 시 리스트 초기화
  };

  const handleDistrictClick = (district) => {
    setSelectedDistrict(district);
    setSelectedArea(""); // Reset selected area when changing district
    const areasList = data[selectedCity].find((d) => d[district])[district];
    setAreas(areasList);

    // 선택 리스트 업데이트
    setSelectionList([selectedCity, district]); // 도시 유지, 구 추가
  };

  const handleAreaClick = (area) => {
    setSelectedArea(area);

    // 선택 리스트 업데이트
    setSelectionList([selectedCity, selectedDistrict, area]); // 도시, 구 유지, 동 추가
  };

  // 초기화 함수
  const handleReset = () => {
    setSelectedCity("");
    setSelectedDistrict("");
    setSelectedArea("");
    setDistricts([]);
    setAreas([]);
    setSelectionList([]); // 초기화 시 리스트도 비움
  };

  useEffect(() => {
    handleOptionSingleClick("지역", selectionList);
  }, [selectionList]);

  // 도시 버튼을 위한 데이터
  const cities = Object.keys(data);

  return (
    <section className={styles.categorySection}>
      <h4 className={styles.categoryTitle}>{"지역"}</h4>
      <HorizontalLine size={1} color={colorStyles.dark_gray} />
      <div className={styles.categoryOptions}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            fontWeight: 600,
          }}
        >
          <span
            onClick={() => active && hadleTitleClick("city")}
            style={{ cursor: active ? "pointer" : "" }}
          >
            {selectedCity ? selectedCity : "시/군"} {">"}
          </span>

          <span
            onClick={() => active && hadleTitleClick("district")}
            style={{ cursor: active ? "pointer" : "" }}
          >
            {selectedDistrict ? selectedDistrict : "시/군/구"} {">"}
          </span>

          <span>{selectedArea ? selectedArea : "읍/면/동"}</span>
          {/* 초기화 버튼 */}
          {/* <LuRefreshCw className={styles.resetButton} onClick={handleReset} /> */}
        </div>
        <HorizontalLine size={1} color={colorStyles.dark_gray} />
        <div className={styles.buttonContainer}>
          <div className={styles.buttonSection}>
            {/* 도시 선택 버튼 */}
            {!selectedCity && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {cities.map((city) => (
                  <button
                    key={city}
                    className={styles.optionButton}
                    onClick={() => active && handleCityClick(city)}
                    style={{
                      backgroundColor:
                        selectedCity === city ? colorStyles.main : "",
                      fontWeight: selectedCity === city ? "bold" : "",
                      borderWidth: selectedCity === city ? "2px" : "",
                      cursor: active ? "pointer" : "",
                    }}
                  >
                    {city}
                  </button>
                ))}
              </div>
            )}

            {/* 구 선택 버튼 */}
            {selectedCity && !selectedDistrict && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {districts.map((district) => (
                  <button
                    key={district}
                    className={styles.optionButton}
                    onClick={() => active && handleDistrictClick(district)}
                    style={{
                      color:
                        selectedDistrict === district ? colorStyles.main : "",
                      borderColor:
                        selectedDistrict === district ? colorStyles.main : "",
                      fontWeight: selectedDistrict === district ? "bold" : "",
                      borderWidth: selectedDistrict === district ? "2px" : "",
                      cursor: active ? "pointer" : "",
                    }}
                  >
                    {district}
                  </button>
                ))}
              </div>
            )}

            {/* 동 선택 버튼 */}
            {selectedCity && selectedDistrict && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {areas.map((area) => (
                  <button
                    key={area}
                    className={styles.optionButton}
                    onClick={() => active && handleAreaClick(area)}
                    style={{
                      color:
                        selectedArea === area
                          ? active
                            ? colorStyles.main
                            : colorStyles.gray
                          : active
                          ? ""
                          : colorStyles.dark_gray,
                      borderColor:
                        selectedArea === area
                          ? active
                            ? colorStyles.main
                            : colorStyles.gray
                          : active
                          ? ""
                          : colorStyles.dark_gray,
                      fontWeight: selectedArea === area ? "bold" : "",
                      borderWidth: selectedArea === area ? "2px" : "",
                      cursor: active ? "pointer" : "",
                    }}
                    disabled={active ? false : true}
                  >
                    {area}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegionCategorySection;
