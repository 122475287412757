import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import HttpCommon from "./service/http_common";
import MapService from "./service/map";
import ContentService from "./service/content";
import config from "./config";

const base_url = config.http.base_url;
const httpClient = new HttpCommon(base_url);
const mapService = new MapService(httpClient);
const contentService = new ContentService(httpClient);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RecoilRoot>
    <BrowserRouter>
      <App mapService={mapService} contentService={contentService} />
    </BrowserRouter>
  </RecoilRoot>
);
