import { useEffect, useState } from "react";
import RegionCategorySection from "../region_category_section/region_category_section";

const StepRegion = ({ data, onDataChange, setIsNextEnabled, isActive }) => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [temporarySelection, setTemporarySelection] = useState({});
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    // console.log(data);

    if (data.length === 3) {
      setSelectedOptions(data);
    }
  }, []);

  const handleOptionSingleClick = (category, option) => {
    setTemporarySelection((prev) => ({
      ...prev,
      [category]: option,
    }));

    if (option.length === 3) {
      setIsNextEnabled(true);
      onDataChange("area", option);
    }
  };

  return (
    <div>
      <h3 style={{ paddingLeft: "10px", fontSize: "22px" }}>
        지역을 선택해주세요
      </h3>
      <div style={{ display: "flex", alignItems: "center" }}>
        <section key={"지역"} className="categorySection">
          <RegionCategorySection
            initData={selectedOptions}
            handleOptionSingleClick={handleOptionSingleClick}
            isReset={isReset}
            active={isActive}
          />
        </section>
      </div>
    </div>
  );
};

export default StepRegion;
