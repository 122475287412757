import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./search_bar.module.css";
import { IoSearchSharp } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";

const SearchBar = ({ placeHolder, onSearch, contentService }) => {
  const colorStyles = useRecoilValue(themeColorsState);
  const inputRef = useRef(null);
  const [isInput, setIsInput] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isSuggestionClicked, setIsSuggestionClicked] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [previousInputValue, setPreviousInputValue] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    if (placeHolder) {
      inputRef.current.value = placeHolder;
      setIsInput(true);
      setShowSuggestions(false);
      setIsSuggestionClicked(true);
    } else {
      inputRef.current.value = "";
    }
  }, [placeHolder]);

  const onClickSearchBtn = async () => {
    if (!inputRef.current) {
      return;
    }
    const inputValue = escapeRegExp(inputRef.current.value.trim());
    try {
      await onSearch(inputValue); // 서버로 요청 전송
      // console.log("서버로 요청 전송 완료");
    } catch (error) {
      console.error("서버 요청 중 에러 발생:", error); // 에러를 확인
    }

    // inputRef.current.value = "";
    // setIsInput(false);
    setSuggestions([]);
    setShowSuggestions(false);
    setIsSuggestionClicked(false);
    setHighlightedIndex(-1);
    setPreviousInputValue("");
  };

  const onClickResetBtn = () => {
    inputRef.current.value = "";
    setIsInput(false);
    setSuggestions([]);
    setShowSuggestions(false);
    setIsSuggestionClicked(false);
    setHighlightedIndex(-1);
    setPreviousInputValue("");
  };

  const handleInputChange = () => {
    const value = inputRef.current.value;
    setIsInput(value !== "");
    setIsSuggestionClicked(false);

    // 입력이 완료된 후 추천을 업데이트하는 타이머 설정
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        if (value && value !== previousInputValue) {
          updateSuggestions();
        } else if (!value) {
          setSuggestions([]);
          setShowSuggestions(false);
        }
      }, 500) // 0.5초 후에 추천 업데이트
    );
  };

  const updateSuggestions = useCallback(async () => {
    if (!inputRef.current) return;
    const currentValue = escapeRegExp(inputRef.current.value);

    if (
      currentValue.length > 0 &&
      currentValue !== previousInputValue &&
      !isSuggestionClicked
    ) {
      // const filteredSuggestions = allSuggestions
      //   .filter((suggestion) =>
      //     suggestion
      //       .toLowerCase()
      //       .includes(inputRef.current.value.toLowerCase())
      //   )
      //   .slice(0, 10);

      const suggestionData = await contentService.getSuggestedSearchTerms(
        currentValue
      );

      const filteredSuggestions = suggestionData?.result.map(
        (item) => item.new_house_nm
      );

      setSuggestions(filteredSuggestions);
      setShowSuggestions(true);
      setPreviousInputValue(currentValue);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, [previousInputValue, isSuggestionClicked]);

  const handleSuggestionClick = (suggestion) => {
    inputRef.current.value = suggestion;
    setSuggestions([]);
    setShowSuggestions(false);
    setIsInput(true);
    setIsSuggestionClicked(true);
    setHighlightedIndex(-1);
    setPreviousInputValue(suggestion);
  };

  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      !event.target.classList.contains(styles.suggestionItem)
    ) {
      setShowSuggestions(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (event.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (event.key === "Enter") {
      if (highlightedIndex >= 0) {
        handleSuggestionClick(suggestions[highlightedIndex]);
      } else if (inputRef.current.value.trim() !== "") {
        onClickSearchBtn();
      }
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "");
  };

  const highlightMatch = (text, query) => {
    const escapedQuery = escapeRegExp(query);
    const parts = text.split(new RegExp(`(${escapedQuery})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === escapedQuery.toLowerCase() ? (
        <span key={index} style={{ color: colorStyles.main, fontWeight: 400 }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <>
      <div className={styles.container}>
        <section className={styles.searchBar}>
          <input
            className={styles.inputBar}
            placeholder="검색어"
            type="text"
            ref={inputRef}
            size={20}
            onChange={handleInputChange}
            onFocus={() => setShowSuggestions(true)}
            onKeyDown={handleKeyDown}
            style={{
              borderRadius:
                showSuggestions && suggestions.length > 0 && `8px 8px 0 0`,
              backgroundColor: colorStyles.main,
              color: "#f5f5f5",
            }}
          />
          {isInput && (
            <button className={styles.resetBtn} onClick={onClickResetBtn}>
              <IoIosCloseCircleOutline className={styles.resetIcon} />
            </button>
          )}
          <button className={styles.searchBtn} onClick={onClickSearchBtn}>
            <IoSearchSharp className={styles.searchIcon} />
          </button>
        </section>
        {showSuggestions && suggestions.length > 0 && (
          <ul className={styles.suggestionsList}>
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className={`${styles.suggestionItem} ${
                  index === highlightedIndex ? styles.highlighted : ""
                }`}
              >
                {highlightMatch(suggestion, inputRef.current.value)}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default SearchBar;
