import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";

const StepPhone = ({ data, onDataChange, setIsNextEnabled }) => {
  const colorStyles = useRecoilValue(themeColorsState);

  const [phone, setPhone] = useState("");

  useEffect(() => {
    data && setPhone(data);
  }, []);
  useEffect(() => {
    const isFormValid = phone !== "";

    if (isFormValid) {
      onDataChange("phone", phone);
      if (phone.length > 8) {
        setIsNextEnabled(true);
      }
    } else {
      onDataChange("phone", "");
      setIsNextEnabled(false);
    }
  }, [phone]);

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      setter(value);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "20px 10px",
        gap: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
          gap: "8px",
        }}
      >
        <span style={{ fontSize: "22px", fontWeight: 600 }}>
          연락처를 입력해주세요
        </span>
        <span style={{ fontSize: "16px", color: colorStyles.gray }}>
          {"'-'를 제외한 숫자만 입력해주세요."}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <input
            style={{
              height: "30px",
              padding: "10px",
              borderRadius: "5px",
              flexGrow: 1,
              fontSize: "16px",
            }}
            type="text"
            placeholder="연락 가능한 연락처를 입력해주세요."
            value={phone}
            onChange={handleInputChange(setPhone)}
            maxLength={"12"}
          />
        </div>
      </div>
    </div>
  );
};

export default StepPhone;
