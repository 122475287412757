import axios from "axios";

export default class HttpCommon {
  constructor(base_url) {
    // this.authErrorEventBus = authErrorEventBus;
    this.http = axios.create({
      baseURL: base_url,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
      timeout: 10000, // 10초 타임아웃 설정
    });
  }
  async fetch(url, options) {
    const { body, method, headers, type } = options;
    const req = {
      url,
      method,
      headers: { ...headers },
      data: body,
      responseType: type,
    };
    try {
      const res = await this.http(req);
      return res.data;
    } catch (error) {
      let errorCode;
      let message;
      let name;

      if (error.code === "ECONNABORTED") {
        // 타임아웃 오류
        errorCode = 524; // 사용자 정의 타임아웃 오류 코드
        message = "Request timed out";
        name = "TIMEOUT";
      } else if (error.response) {
        errorCode = error.response.status;
        const data = error.response.data;

        switch (errorCode) {
          case 400:
            message = data && data.message ? data.message : "잘못된 요청";
            name = "BAD_REQUEST";
            break;
          case 401:
            message =
              data && data.message
                ? data.message
                : "로그인이 필요한 서비스입니다";
            name = "UNAUTHORIZED";
            break;
          case 404:
            message =
              data && data.message ? data.message : "페이지를 찾을 수 없습니다";
            name = "NOT_FOUND";
            break;
          default:
            message =
              data && data.message ? data.message : "나중에 다시 시도해주세요";
            name = "UNKNOWN";
            break;
        }
      } else {
        errorCode = "UNKNOWN";
        message = "connection error";
        name = "UNKNOWN";
      }

      const err = new Error(message);
      err.name = name;
      err.code = errorCode;
      throw err;
    }
  }
}
