import { atom } from "recoil";

export const screenStylesState = atom({
  key: "screenStylesState",
  default: {
    width: "735px",
    backgroundColor: "white",
    padding: "16px",
    margin: "16px",
    buttonWidth: "100px",
    h1: "24px",
    h2: "22px",
    h3: "20px",
    h4: "18px",
    h5: "16px",
    h6: "14px",
  },
});
