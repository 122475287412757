import React, { useState, useEffect } from "react";
import RegionCategorySection from "../components/region_category_section/region_category_section";
import CostSlider from "../components/cost_slider/cost_slider";
import SpaceSizeSection from "../components/space_size_section/space_size_section";

const TestPage = () => {
  const [data, setData] = useState({});
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [districts, setDistricts] = useState([]);
  const [areas, setAreas] = useState([]);

  // JSON 파일을 불러옵니다.
  useEffect(() => {
    fetch("/regions.json")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching the JSON file:", error));
  }, []);

  const handleCityClick = (city) => {
    setSelectedCity(city);
    setSelectedDistrict("");
    setSelectedArea("");
    setDistricts(data[city].map((district) => Object.keys(district)[0]));
    setAreas([]); // Reset areas when changing cities
  };

  const handleDistrictClick = (district) => {
    setSelectedDistrict(district);
    setSelectedArea(""); // Reset selected area when changing district
    const areasList = data[selectedCity].find((d) => d[district])[district];
    setAreas(areasList);
  };

  const handleAreaClick = (area) => {
    setSelectedArea(area);
  };

  // 도시 버튼을 위한 데이터
  const cities = Object.keys(data);

  return <SpaceSizeSection handleOptionSingleClick={() => {}} />;
};

export default TestPage;
