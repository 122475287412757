import { useEffect, useState } from "react";
import CustomSelect from "../custom_select/custom_select";

const StepType = ({
  data,
  presaleId,
  onDataChange,
  setIsNextEnabled,
  mapService,
  isActive,
}) => {
  const [dong, setDong] = useState("");
  const [ho, setHo] = useState("");
  const [floor, setFloor] = useState("");
  const [originList, setOriginList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [aptType, setAptType] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // console.log(data);

    if (data) {
      data.dong && setDong(data.dong);
      data.ho && setHo(data.ho);
      data.house_type && setSelectedType(formatHouseData(data.house_type));
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const fetchPresaleOptions = async () => {
      try {
        const response = await mapService.getTypeList(presaleId);
        if (response.result.length > 0) {
          setOriginList(response.result);
          setTypeList(formatHouseData(response.result));
        }
      } catch (err) {
        setError("타입 정보를 불러오는데 실패했습니다.");
        console.log(err);
      }
    };

    fetchPresaleOptions();
  }, [presaleId]);

  useEffect(() => {
    const isFormValid = dong !== "" && ho !== "";

    if (isFormValid) {
      onDataChange("dong", dong);
      onDataChange("ho", ho);
    }
  }, [dong, ho]);

  useEffect(() => {
    const isFormValid = dong !== "" && ho !== "" && selectedType !== "";
    isFormValid ? setIsNextEnabled(true) : setIsNextEnabled(false);
  }, [dong, ho, selectedType]);

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;

    if (e.target.name === "type") {
      setter(value);
      onDataChange("house_type", value);
    } else {
      if (value === "" || /^\d+$/.test(value)) {
        setter(value);
      }
    }
  };

  const handleSelectChange = (selected) => {
    // console.log(selected);
    const id = Number(selected.id);
    // console.log(originList[id]);
    setSelectedType(selected.name);
    onDataChange("house_type", originList[id].house_type);
  };

  const formatHouseData = (data) => {
    if (typeof data === "object") {
      return data.map((item, index) => {
        let { house_type } = item;

        // house_type 처리: 문자가 있으면 숫자와 합치기
        const typeMatch = house_type.match(/^(\d+)\.(\d+)([A-Z])?$/);
        if (typeMatch) {
          const integerPart = parseInt(typeMatch[1], 10);
          const charPart = typeMatch[3] ? typeMatch[3] : "";
          // 문자가 있는 경우만 숫자+문자 형식으로 변환
          if (charPart) {
            house_type = `${integerPart}${charPart}`;
          }
        }
        // 최종 값 합치기
        return {
          id: index,
          name: `${house_type}`,
        };
      });
    } else {
      let house_type = data;
      const typeMatch = house_type.match(/^(\d+)\.(\d+)([A-Z])?$/);
      if (typeMatch) {
        const integerPart = parseInt(typeMatch[1], 10);
        const charPart = typeMatch[3] ? typeMatch[3] : "";
        // 문자가 있는 경우만 숫자+문자 형식으로 변환
        if (charPart) {
          house_type = `${integerPart}${charPart}`;
        }
      }
      // 최종 값 합치기
      return house_type;
    }
  };
  // console.log(data, isActive);

  return (
    <div>
      <h3 style={{ paddingLeft: "10px", fontSize: "22px" }}>
        동, 호수, 타입을 입력해주세요
      </h3>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginLeft: "10px",
          gap: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: "70%" }}>
            <input
              style={{
                width: "90%",
                height: "30px",
                padding: "10px",
                borderRadius: "5px",
                fontSize: "16px",
              }}
              type="text"
              placeholder="동을 입력해주세요"
              value={dong}
              onChange={handleInputChange(setDong)}
              disabled={isActive ? false : true}
            />
          </div>
          <span
            style={{ fontSize: "16px", fontWeight: 600, marginLeft: "5px" }}
          >
            동
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: "70%" }}>
            <input
              style={{
                width: "90%",
                height: "30px",
                padding: "10px",
                borderRadius: "5px",
                fontSize: "16px",
              }}
              type="text"
              placeholder="호수를 입력해주세요"
              value={ho}
              onChange={handleInputChange(setHo)}
            />
          </div>
          <span
            style={{ fontSize: "16px", fontWeight: 600, marginLeft: "5px" }}
          >
            호
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: "70%" }}>
            {!isLoading && data.house_type ? (
              <div style={{ display: "flex" }}>
                <input
                  style={{
                    width: "100%",
                    height: "30px",
                    padding: "10px",
                    borderRadius: "5px",
                    fontSize: "16px",
                  }}
                  type="text"
                  name="type"
                  placeholder="84A, 85B 등 타입을 입력해주세요"
                  value={selectedType}
                  onChange={handleInputChange(setSelectedType)}
                  disabled={isActive ? false : true}
                />
              </div>
            ) : typeList.length > 0 ? (
              <CustomSelect
                placeholder="타입 선택"
                options={typeList}
                onChange={handleSelectChange}
                inputValue={selectedType}
              />
            ) : (
              <div style={{ display: "flex" }}>
                <input
                  style={{
                    width: "100%",
                    height: "30px",
                    padding: "10px",
                    borderRadius: "5px",
                    fontSize: "16px",
                  }}
                  type="text"
                  name="type"
                  placeholder="84A, 85B 등 타입을 입력해주세요"
                  value={selectedType}
                  onChange={handleInputChange(setSelectedType)}
                />
              </div>
            )}
          </div>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
              marginLeft: "5px",
            }}
          >
            타입
          </span>
        </div>
      </div>
    </div>
  );
};

export default StepType;
