import { useEffect } from "react";
import ScreenSizeObserver from "./components/screen_size_observer/screen_size_observer";
import { Route, Routes } from "react-router-dom";
import MainPage from "./pages/main";
import SearchResultPage from "./pages/search_result";
import TestPage from "./pages/test";
import DetailPage from "./pages/detail";

function App({ mapService, contentService }) {
  const setScreenSize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    setScreenSize();
  });

  return (
    <>
      <ScreenSizeObserver />
      <Routes>
        <Route
          path="/"
          element={<MainPage contentService={contentService} />}
        />
        <Route
          path="/search"
          element={
            <SearchResultPage
              mapService={mapService}
              contentService={contentService}
            />
          }
        />
        <Route
          path="/detail"
          element={<DetailPage mapService={mapService} />}
        />
        <Route path="/test" element={<TestPage />} />
      </Routes>
    </>
  );
}

export default App;
