import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";
import { Slider } from "@mui/material";

const StepTime = ({ data, onDataChange, setIsNextEnabled }) => {
  const colorStyles = useRecoilValue(themeColorsState);

  const [inputValues, setInputValues] = useState([1, 24]);

  // 시간 범위 설정 (오전, 점심시간 등)
  const timeRanges = {
    morning: [8, 11],
    lunch: [11, 13],
    afternoon: [13, 18],
    evening: [18, 20],
    lateEvening: [20, 23],
  };

  // 활성화된 버튼을 추적하는 상태
  const [activeButton, setActiveButton] = useState("");

  useEffect(() => {
    data && setInputValues(data);
  }, []);

  useEffect(() => {
    const isFormValid = inputValues !== "";

    if (isFormValid) {
      onDataChange("possible_time", inputValues);
      setIsNextEnabled(true);
    } else {
      onDataChange("possible_time", "");
      setIsNextEnabled(false);
    }

    // 슬라이더 값이 버튼의 값과 일치할 경우 버튼 활성화
    const activeBtn = Object.keys(timeRanges).find(
      (key) => JSON.stringify(timeRanges[key]) === JSON.stringify(inputValues)
    );
    setActiveButton(activeBtn || "");
  }, [inputValues]);

  const isWithinRange = (value, range) => {
    return value[0] >= range[0] && value[1] <= range[1];
  };

  const handleSliderChange = (event, newValue) => {
    setInputValues(newValue); // 스크롤이 바뀔 때 input 필드 업데이트
    let newActiveButton = "";
    for (const [key, range] of Object.entries(timeRanges)) {
      if (isWithinRange(newValue, range)) {
        newActiveButton = key;
        break;
      }
    }
    setActiveButton(newActiveButton);
  };

  const handleButtonChange = (newValue, buttonKey) => {
    setInputValues(newValue);
    setActiveButton(buttonKey); // 버튼 활성화
  };

  return (
    <div>
      <h3 style={{ paddingLeft: "10px", fontSize: "22px" }}>
        연락 가능 시간을 선택해주세요.
      </h3>
      <span
        style={{
          paddingLeft: "10px",
          fontSize: "22px",
          fontWeight: 600,
          color: colorStyles.main,
        }}
      >
        {`${inputValues[0]}시 ~ ${inputValues[1]}시`}
      </span>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "90%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Slider
              value={inputValues}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              sx={{
                width: "90%",
                color: colorStyles.main,
                margin: "40px 0px",
                "& .MuiSlider-thumb": { color: "lightgray" },
                "& .MuiSlider-markLabel": { color: "#49454f" },
                "& .MuiSlider-track": { height: "1px" },
              }}
              min={1}
              max={24}
              step={1}
              marks={[
                { value: 1, label: "1시" },
                { value: 12, label: "12시" },
                { value: 24, label: "24시" },
              ]}
            />
            <section
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
                width: "100%",
                flexGrow: 1,
              }}
            >
              {Object.entries(timeRanges).map(([key, range]) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    width: "90%",
                    height: "50px",
                    border: `1px solid ${colorStyles.gray}`,
                    borderRadius: "5px",
                    alignItems: "center",
                    paddingLeft: "10px",
                    color: "#49454F",
                    fontSize: "16px",
                    cursor: "pointer",
                    borderColor: activeButton === key ? colorStyles.main : "",
                    borderWidth: activeButton === key ? "2px" : "1px",
                    color: activeButton === key ? colorStyles.main : "",
                    fontWeight: activeButton === key ? 600 : "",
                  }}
                  onClick={() => handleButtonChange(range, key)}
                >
                  {key === "morning"
                    ? "오전"
                    : key === "lunch"
                    ? "점심시간"
                    : key === "afternoon"
                    ? "오후"
                    : key === "evening"
                    ? "저녁시간"
                    : "저녁시간 이후"}
                </div>
              ))}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTime;
