import React, { useState, useRef, useEffect, useMemo } from "react";
import styles from "./detail_mapinfo.module.css"; // CSS 모듈
import HorizontalLine from "../seperators/horizontal_line";
import { calculateUnitPrice } from "../../service/calculate";
import SellRealEstateButton from "../sell_real_estate_button/sell_real_estate_button";
import CustomCarousel from "../custom_carousel/custom_carousel";
const DetailWindow = ({
  data,
  windowHeight,
  handleWindowHeight,
  handleItemClick,
  listRef,
  currentData,
  setCurrentData,
  setShowSellPanel,
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false); // 전체 화면 상태
  const [isDragging, setIsDragging] = useState(false); // 드래그 상태
  const startPos = useRef(0); // 드래그 시작 시 마우스 위치 저장

  const [visibleItems, setVisibleItems] = useState(5); // 처음에는 5개의 아이템만 표시

  // 캐러셀 관련 상태와 ref
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isCarouselDragging, setIsCarouselDragging] = useState(false);
  const carouselRef = useRef(null);
  const carouselStartX = useRef(null);

  const windowHeightRef = useRef(windowHeight);

  // 컴포넌트 마운트 시 이벤트 추가
  useEffect(() => {
    const preventPullToRefresh = (e) => {
      if (window.scrollY === 0 && e.touches[0].clientY > 0) {
        e.preventDefault();
      }
    };

    window.addEventListener("touchmove", preventPullToRefresh, {
      passive: false,
    });

    // 컴포넌트 언마운트 시 이벤트 제거
    return () => {
      window.removeEventListener("touchmove", preventPullToRefresh);
    };
  }, []);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [visibleItems]);

  // 드래그 시작 핸들러
  const handleDragStart = (e) => {
    e.preventDefault(); // 기본 동작 방지
    e.stopPropagation(); // 이벤트 전파 중지
    setIsDragging(true);
    startPos.current = e.clientY; // 마우스 Y 좌표 저장
  };

  // 터치 이벤트 핸들러
  const handleTouchStart = (e) => {
    // e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
    startPos.current = e.touches[0].clientY;
  };

  // 드래그/터치 이동 처리 함수
  const handleMove = (clientY) => {
    if (!isDragging) return;

    const dragDistance = startPos.current - clientY;
    const newHeight = windowHeight + (dragDistance / window.innerHeight) * 100;
    // console.log("업데이트되는 높이: ", newHeight);
    windowHeightRef.current = newHeight;

    if (newHeight > 70) {
      handleWindowHeight(100); // 70% 이상이면 전체 화면
    } else if (newHeight > 30 && newHeight <= 60) {
      handleWindowHeight(35); // 30~60% 사이에서는 원래 크기(40) 유지
    } else if (newHeight <= 30) {
      handleWindowHeight(10); // 30% 이하일 때 창의 일부만 남기기
      // onClose();
      // handleOpenDetail(false);
    }
  };

  // 마우스 이동 핸들러
  const handleDragMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    handleMove(e.clientY);
  };

  // 터치 이동 핸들러
  const handleTouchMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    handleMove(e.touches[0].clientY);
  };

  // 드래그/터치 종료 처리 함수
  const handleDragEnd = () => {
    setIsDragging(false);
    // console.log("드래그 끝났을때: ", windowHeightRef.current, windowHeight);

    if (windowHeight === 10) {
      if (windowHeightRef.current > 10) {
        handleWindowHeight(35);
      }
    } else if (windowHeight === 35) {
      if (windowHeightRef.current > 35) {
        handleWindowHeight(100);
      } else {
        handleWindowHeight(10);
      }
    } else if (windowHeight === 100) {
      if (windowHeightRef.current < 100) {
        handleWindowHeight(35);
      }
    }

    // if (windowHeight >= 100) {
    //   setIsFullScreen(true);
    // } else {
    //   setIsFullScreen(false);
    // }
  };

  // 마우스 업 또는 드래그 끝났을 때 이벤트 리스너
  // const handleMouseUp = () => {
  //   if (isDragging) {
  //     setIsDragging(false);
  //   }
  // };

  // 이벤트 리스너 설정
  useEffect(() => {
    if (isDragging) {
      // 마우스 이벤트
      window.addEventListener("mousemove", handleDragMove);
      window.addEventListener("mouseup", handleDragEnd);

      // 터치 이벤트
      window.addEventListener("touchmove", handleTouchMove, { passive: false });
      window.addEventListener("touchend", handleDragEnd);
    }

    return () => {
      // 마우스 이벤트 정리
      window.removeEventListener("mousemove", handleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);

      // 터치 이벤트 정리
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleDragEnd);
    };
  }, [isDragging]);

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      // 스크롤이 끝까지 내려왔을 때, 다음 5개 아이템 추가
      if (
        scrollTop + clientHeight >= scrollHeight - 10 &&
        visibleItems < data.length
      ) {
        loadMoreItems();
      }
    }
  };

  // 더 많은 아이템을 로드
  const loadMoreItems = () => {
    const newVisibleItems = visibleItems + 5;
    setCurrentData(data.slice(0, newVisibleItems)); // 5개씩 추가
    setVisibleItems(newVisibleItems);
  };

  // 캐러셀용 드래그 시작 핸들러
  const handleCarouselDragStart = (e) => {
    carouselStartX.current = e.pageX - carouselRef.current.offsetLeft;
    setIsCarouselDragging(true);
  };

  // 캐러셀용 드래그 이동 핸들러
  const handleCarouselDragMove = (e) => {
    if (!isCarouselDragging) return;
    e.preventDefault();
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - carouselStartX.current) * 3; // * 3 for faster sliding
    if (walk < -100) {
      handleNextSlide();
      setIsCarouselDragging(false);
    } else if (walk > 100) {
      handlePrevSlide();
      setIsCarouselDragging(false);
    }
  };

  // 캐러셀용 드래그 종료 핸들러
  const handleCarouselDragEnd = () => {
    setIsCarouselDragging(false);
  };

  const handleNextSlide = () => {
    setCurrentSlide(
      (prev) => (prev + 1) % Math.ceil(currentData.presale_info.length / 5)
    );
  };

  const handlePrevSlide = () => {
    setCurrentSlide(
      (prev) =>
        (prev - 1 + Math.ceil(currentData.presale_info.length / 5)) %
        Math.ceil(currentData.presale_info.length / 5)
    );
  };
  // console.log(currentData[0].max_detail[0]);

  const renderContent = () => {
    // console.log(currentData);

    const getContractState = (obj) => {
      const today = new Date();
      const beginDate = new Date(obj.rcept_bgnde);
      const endDate = new Date(obj.rcept_endde);
      if (today < beginDate) {
        return (
          <span
            style={{
              padding: "2px",
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              border: "1px solid #ffcc00",
              backgroundColor: "#ffcc00",
            }}
          >
            {"분양전"}
          </span>
        );
      } else if (today > endDate) {
        return (
          <span
            style={{
              padding: "2px",
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              border: "1px solid #34C759",
              backgroundColor: "#34C759",
            }}
          >
            {"분양완료"}
          </span>
        );
      } else {
        return (
          <span
            style={{
              padding: "2px",
              fontSize: "14px",
              fontWeight: 700,
              color: "white",
              border: "1px solid #5856D6",
              backgroundColor: "#5856D6",
            }}
          >
            {"분양중"}
          </span>
        );
      }
    };

    const getUnitPrice = (obj) => {
      // console.log(obj);
      if (!obj) {
        return;
      }
      if (obj.length > 0) {
        const perPriceObj = calculateUnitPrice(
          Number(obj[0].house_supply_area),
          Number(obj[0].max_supply_price),
          "meter"
        );
        const perSquarePrice = perPriceObj.pricePerSquareMeter;
        const perPyeongPrice = perPriceObj.pricePerPyeong;
        return `분양가 ${perSquarePrice.toLocaleString()}만원/m² (${perPyeongPrice.toLocaleString()}만원/평)`;
      } else {
        return `정보없음`;
      }
    };

    const getSupplyType = (obj) => {
      const supplyType = obj.house_secd_nm;
      if (supplyType === "APT") {
        return "아파트";
      }
      return supplyType;
    };

    if (data) {
      return (
        <>
          <ul
            ref={listRef}
            className={styles.itemList}
            style={{
              height: "100%",
              overflowY: "auto",
              overflowY: "scroll", // 세로 스크롤만 활성화
              scrollbarWidth: "none", // Firefox에서 스크롤 바 숨기기
              msOverflowStyle: "none", // IE 및 Edge에서 스크롤 바 숨기기
              "&::WebkitScrollbar": { display: "none" },
            }}
          >
            {currentData.map((item, index) => (
              <li key={index} style={{ paddingBottom: "15px" }}>
                <div
                  className={styles.selectedItemDetails}
                  onClick={() => handleItemClick(item)}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                      margin: 5,
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: 300,
                      }}
                    >
                      {getContractState(item)}
                    </span>
                    <span
                      style={{ flex: 8, fontSize: "18px", fontWeight: "bold" }}
                    >
                      {item.house_nm}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      marginLeft: "5px",
                      fontSize: "16px",
                    }}
                  >
                    <span>{getUnitPrice(item.max_detail)}</span>
                    <span>
                      {`${getSupplyType(item)} | 분양 ${
                        item.tot_suply_hshldco
                      } 세대 | `}
                      {item.max_detail[0]?.lcl_cmp_rate === undefined ||
                      item.max_detail[0]?.lcl_cmp_rate < 0
                        ? "미분양"
                        : `경쟁률 ${item.max_detail[0]?.lcl_cmp_rate}`}
                    </span>
                    <span>{item.hssply_adres}</span>
                  </div>
                </div>
                {item.presale_info?.length > 0 && (
                  <CustomCarousel subList={item.presale_info} />
                )}
              </li>
            ))}
          </ul>
        </>
      );
    }
  };

  return (
    <>
      <div
        className={styles.detailWindow}
        style={{ height: `calc(var(--vh, 1vh) * ${windowHeight})` }} // 창 높이를 `vh`로 설정
      >
        <div
          className={styles.handleBox}
          onMouseDown={handleDragStart}
          onTouchStart={handleTouchStart}
        >
          <div className={styles.dragHandle} />
        </div>

        <div className={styles.content}>
          <h3 className={styles.contentTitle} style={{ marginTop: 0 }}>
            {"검색 결과"}
          </h3>
          <HorizontalLine size={1} color={"#D9D9D9"} />
          {renderContent()}
        </div>
        <SellRealEstateButton
          onClick={() => {}}
          handleClick={setShowSellPanel}
        />
      </div>
    </>
  );
};
export default DetailWindow;
