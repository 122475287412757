import { useEffect, useState } from "react";

const StepPrice = ({ data, onDataChange, setIsNextEnabled }) => {
  const [price, setPrice] = useState("");

  useEffect(() => {
    data && setPrice(data);
  }, []);

  useEffect(() => {
    const isFormValid = price !== "";

    if (isFormValid) {
      onDataChange("hope_price", price);
      setIsNextEnabled(true);
    } else {
      onDataChange("hope_price", "");
      setIsNextEnabled(false);
    }
  }, [price]);

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      setter(value);
    }
  };

  return (
    <div>
      <h3 style={{ paddingLeft: "10px", fontSize: "22px" }}>
        희망 프리미엄 가격을 입력해주세요
      </h3>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "95%" }}>
          <input
            style={{
              height: "30px",
              padding: "10px",
              borderRadius: "5px",
              flexGrow: 1,
              fontSize: "16px",
            }}
            type="text"
            placeholder="희망하는 프리미엄 가격을 입력해주세요."
            value={price}
            onChange={handleInputChange(setPrice)}
          />
          <span
            style={{
              fontSize: "20px",
              fontWeight: 600,
              marginLeft: "5px",
            }}
          >
            만원
          </span>
        </div>
      </div>
    </div>
  );
};

export default StepPrice;
