import React, { useState, useEffect, useCallback } from "react";
import Slider from "@mui/material/Slider";
import styles from "./space_size_section.module.css";
import HorizontalLine from "../seperators/horizontal_line";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";

const SpaceSizeSection = ({
  initData,
  handleOptionSingleClick,
  handleSpaceChange,
  isReset,
}) => {
  const colorStyles = useRecoilValue(themeColorsState);
  const initialSpaceRange = [0, 50];
  const [selectedButton, setSelectedButton] = useState(""); // 클릭된 버튼을 추적
  const [selectedTitle, setSelectedTitle] = useState("전체"); // 버튼에 '전체'가 없어서 따로 만들어 줌
  const [inputValues, setInputValues] = useState(initialSpaceRange); // input 필드 값 관리
  const buttonCategories = [
    "10평 이하",
    "10평대",
    "20평대",
    "30평대",
    "40평대",
    "50평 이상",
  ];
  useEffect(() => {
    if (initData) {
      setInputValues(initData);
      if (initData[0] === 0 && initData[1] === 10) {
        setSelectedButton(buttonCategories[0]);
        setSelectedTitle(buttonCategories[0]);
      } else if (initData[0] === 10 && initData[1] === 20) {
        setSelectedButton(buttonCategories[1]);
        setSelectedTitle(`${initData[0]}평 ~ ${initData[1]}평`);
      } else if (initData[0] === 20 && initData[1] === 30) {
        setSelectedButton(buttonCategories[2]);
        setSelectedTitle(`${initData[0]}평 ~ ${initData[1]}평`);
      } else if (initData[0] === 30 && initData[1] === 40) {
        setSelectedButton(buttonCategories[3]);
        setSelectedTitle(`${initData[0]}평 ~ ${initData[1]}평`);
      } else if (initData[0] === 40 && initData[1] === 50) {
        setSelectedButton(buttonCategories[4]);
        setSelectedTitle(`${initData[0]}평 ~ ${initData[1]}평`);
      } else if (initData[0] === 50 && initData[1] === 50) {
        setSelectedButton(buttonCategories[5]);
        setSelectedTitle(buttonCategories[5]);
      } else {
        setSelectedTitle(`${initData[0]}평 ~ ${initData[1]}평`);
      }
    }
    // console.log("InitData", initData);
  }, [initData]);

  const handleButtonClick = (newTitle) => {
    // 클릭된 버튼 업데이트
    if (newTitle === selectedButton) {
      setSelectedButton("");
      setSelectedTitle("전체");
      setInputValues(initialSpaceRange);
    } else {
      setSelectedButton(newTitle);

      if (newTitle === "10평 이하") {
        setInputValues([0, 10]);
        setSelectedTitle(newTitle);
      } else if (newTitle === "10평대") {
        setInputValues([10, 20]);
        setSelectedTitle("10평 ~ 20평");
      } else if (newTitle === "20평대") {
        setInputValues([20, 30]);
        setSelectedTitle("20평 ~ 30평");
      } else if (newTitle === "30평대") {
        setInputValues([30, 40]);
        setSelectedTitle("30평 ~ 40평");
      } else if (newTitle === "40평대") {
        setInputValues([40, 50]);
        setSelectedTitle("40평 ~ 50평");
      } else if (newTitle === "50평 이상") {
        setInputValues([50, 50]);
        setSelectedTitle(newTitle);
      }
    }
  };

  useEffect(() => {
    handleOptionSingleClick("면적", inputValues);
  }, [inputValues]);

  const handleSliderChange = (event, newValue) => {
    handleSpaceChange("면적", newValue);
    setInputValues(newValue); // 스크롤이 바뀔 때 input 필드 업데이트
    if (newValue[0] === 0 && newValue[1] === 10) {
      setSelectedButton(buttonCategories[0]);
      setSelectedTitle(buttonCategories[0]);
    } else if (newValue[0] === 10 && newValue[1] === 20) {
      setSelectedButton(buttonCategories[1]);
      setSelectedTitle(`${newValue[0]}평 ~ ${newValue[1]}평`);
    } else if (newValue[0] === 20 && newValue[1] === 30) {
      setSelectedButton(buttonCategories[2]);
      setSelectedTitle(`${newValue[0]}평 ~ ${newValue[1]}평`);
    } else if (newValue[0] === 30 && newValue[1] === 40) {
      setSelectedButton(buttonCategories[3]);
      setSelectedTitle(`${newValue[0]}평 ~ ${newValue[1]}평`);
    } else if (newValue[0] === 40 && newValue[1] === 50) {
      setSelectedButton(buttonCategories[4]);
      setSelectedTitle(`${newValue[0]}평 ~ ${newValue[1]}평`);
    } else if (newValue[0] === 50 && newValue[1] === 50) {
      setSelectedButton(buttonCategories[5]);
      setSelectedTitle(buttonCategories[5]);
    } else {
      setSelectedButton("");
      setSelectedTitle(`${newValue[0]}평 ~ ${newValue[1]}평`);
    }
  };

  const handleReset = () => {
    setInputValues(initialSpaceRange);
    handleSpaceChange("면적", initialSpaceRange);
    setSelectedButton("");
    setSelectedTitle("전체");
  };

  useEffect(() => {
    isReset && handleReset();
  }, [isReset]);

  return (
    <section className={styles.categorySection}>
      <h4 className={styles.categoryTitle}>{"면적"}</h4>
      <HorizontalLine size={1} color={colorStyles.dark_gray} />
      <div className={styles.categoryOptions}>
        <div
          style={{
            display: "flex",
            padding: "10px",
            gap: "10px",
            fontWeight: 600,
          }}
        >
          <span>{selectedTitle}</span>
        </div>
        <HorizontalLine size={1} color={colorStyles.dark_gray} />

        <div className={styles.rangeSliderContainer}>
          <Slider
            value={inputValues || [0, 50]}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            sx={{
              width: "80%",
              color: colorStyles.main,
              "& .MuiSlider-thumb": { color: "lightgray" },
              "& .MuiSlider-markLabel": { color: "#49454f" },
              "& .MuiSlider-track": { height: "1px" },
            }}
            max={50}
            step={1}
            disableSwap
            marks={[
              { value: 0, label: "0" },
              { value: 10, label: "10" },
              { value: 20, label: "20" },
              { value: 30, label: "30" },
              { value: 40, label: "40" },
              { value: 50, label: "50" },
            ]}
          />
        </div>
        <div className={styles.buttonContainer}>
          {buttonCategories.map((title) => (
            <button
              key={title}
              className={styles.optionButton}
              onClick={() => handleButtonClick(title)}
              style={{
                textAlign: "center",
                color: selectedButton === title ? colorStyles.main : "",
                borderColor: selectedButton === title ? colorStyles.main : "",
                fontWeight: selectedButton === title ? "bold" : "",
                borderWidth: selectedButton === title ? "2px" : "",
              }}
            >
              {title}
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SpaceSizeSection;
