import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";
import CollectionAgreement from "../collection_agreement/collection_agreement";

const StepRef = ({ data, onDataChange }) => {
  const colorStyles = useRecoilValue(themeColorsState);

  const [content, setContent] = useState("");
  const [inputCount, setInputCount] = useState(0);
  const maxCount = 200;

  const [isAgreement, setIsAgreement] = useState(false);
  const [showAgreement, setShowAgreement] = useState(false);

  useEffect(() => {
    if (data) {
      setContent(data);
      setInputCount(data.length);
    }
  }, []);

  useEffect(() => {
    onDataChange("etc", content);
  }, [content]);

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "agree") {
      const checked = e.target.checked;
      setter(checked);
      onDataChange(name, checked);
    } else {
      setter(value);
      setInputCount(e.target.value.length);
    }
  };

  const closeAgreement = () => {
    setShowAgreement(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px 10px",
          gap: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
            gap: "8px",
          }}
        >
          <span
            style={{
              fontSize: "21px",
              fontWeight: 600,
            }}
          >
            통화 상담시 참고할만한 사항을 적어주세요
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <textarea
              style={{
                width: "95%",
                height: "160px",
                borderRadius: "5px",
                padding: "10px",
                flexGrow: 1,
                resize: "none",
                borderColor: colorStyles.dark_gray,
                color: colorStyles.gray,
                fontFamily: "Pretendard",
                fontSize: "16px",
              }}
              value={content}
              placeholder="통화 상담시 참고할만한 사항을 적어주세요."
              onChange={handleInputChange(setContent)}
              maxLength={maxCount}
            />
            <span
              style={{ display: "flex", alignSelf: "end", fontSize: "14px" }}
            >
              <span>{inputCount}</span>/<span>{maxCount}</span>
            </span>
          </div>
        </div>
        <div>
          <label>
            <input
              name="agree"
              type="checkbox"
              checked={isAgreement}
              onChange={handleInputChange(setIsAgreement)}
            />
            개인정보 수집 및 이용에 동의합니다
          </label>
          <span
            style={{
              fontSize: "10px",
              marginLeft: "5px",
              color: colorStyles.main,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setShowAgreement(true)}
          >
            자세히보기
          </span>
        </div>
      </div>
      {showAgreement && <CollectionAgreement onClose={closeAgreement} />}
    </>
  );
};

export default StepRef;
