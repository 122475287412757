import React, { useState, useEffect, useCallback } from "react";
import styles from "./scroll_to_top_button.module.css";
import { FaChevronUp } from "react-icons/fa";

const ScrollToTopButton = ({ target }) => {
  const [isVisible, setIsVisible] = useState(false);

  // 스크롤 이벤트 핸들러
  const toggleVisibility = useCallback(() => {
    const scrollTop = target.current?.scrollTop || 0;

    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  // 화면 상단으로 스크롤하는 함수
  const scrollToTop = () => {
    if (target.current) {
      target.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const targetContainer = target.current;
    if (targetContainer) {
      targetContainer.addEventListener("scroll", toggleVisibility);
      return () => {
        targetContainer.removeEventListener("scroll", toggleVisibility);
      };
    }
  }, [toggleVisibility]);

  return (
    <div className={styles.container}>
      {isVisible && (
        <div onClick={scrollToTop} className={styles.button}>
          <FaChevronUp fill="white" />
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
