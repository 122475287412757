export const config = {
  http: {
    base_url: process.env.REACT_APP_BASE_URL,
  },
  s3: {
    access_key: process.env.REACT_APP_S3_ACCESS_KEY,
    secret_key: process.env.REACT_APP_S3_SECRET_KEY,
    region: process.env.REACT_APP_S3_REGION,
    version: process.env.REACT_APP_S3_VERSION,
    bucket_name: process.env.REACT_APP_S3_BUCKET_NAME,
  },
};

export default config;
