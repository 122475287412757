import { useRecoilValue } from "recoil";
import styles from "./collection_agreement.module.css";
import { themeColorsState } from "../../stores/theme_colors_state";
import { IoMdClose } from "react-icons/io";
import HorizontalLine from "../seperators/horizontal_line";

const CollectionAgreement = ({ onClose }) => {
  const colorStyles = useRecoilValue(themeColorsState);
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: "50%",
          transform: "translate(-50%, 0)",
          width: "100%",
          height: "100vh",
          backgroundColor: colorStyles.dark_gray,
          zIndex: 999, // 모달 아래 배경 레이어
        }}
      />
      <div className={styles.modal}>
        <div
          className="closeButton"
          style={{
            position: "fixed",
            top: 10,
            right: 10,
            width: "24px",
            height: "24px",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          <IoMdClose size={24} />
        </div>
        <div
          className="model_content"
          style={{
            display: "flex",
            flexDirection: "column",
            color: colorStyles.gray,
          }}
        >
          <span
            className="title"
            style={{
              marginBottom: "15px",
              fontSize: "16px",
              fontWeight: "bold",
              color: "black",
            }}
          >
            개인정보 수집 및 이용동의
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "15px",
              gap: "8px",
            }}
          >
            <span style={{ fontSize: "12px" }}>
              파운드는 아래의 목적으로 개인정보 수집 및 이용하며,{" "}
            </span>
            <span style={{ fontSize: "12px" }}>
              회원의 개인정보를 안전하게 취급하는데 최선을 다합니다.
            </span>
          </div>
          <HorizontalLine size={1} color={colorStyles.dark_gray} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "15px 0",
              gap: "8px",
            }}
          >
            <div style={{ display: "flex" }}>
              <span style={{ flex: 2, fontSize: "12px" }}>목적</span>
              <span style={{ flex: 8, color: "black", fontSize: "12px" }}>
                거래 의사 및 소유권 확인
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <span style={{ flex: 2, fontSize: "12px" }}>항목</span>
              <span style={{ flex: 8, color: "black", fontSize: "12px" }}>
                연락처, 사진(제출시)
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <span style={{ flex: 2, fontSize: "12px" }}>보유기간</span>
              <span
                style={{
                  flex: 8,
                  color: "black",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                거래 완료 시 즉시 파기
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: "10px",
              gap: "6px",
            }}
          >
            <span>
              서비스 제공을 위해 필요한 최소한의 개인정보이므로 동의를 해주셔야
            </span>
            <span>서비스 제공이 가능합니다.</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionAgreement;
