import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { screenStylesState } from "../../stores/screen_styles_state";

const ScreenSizeObserver = () => {
  const setScreenStyles = useSetRecoilState(screenStylesState);

  useEffect(() => {
    const updateStylesBasedOnWidth = () => {
      const width = window.innerWidth;

      if (width <= 375) {
        setScreenStyles({
          width: "100%",
          padding: "16px",
          margin: "16px",
          buttonWidth: "100px",
          h1: "24px",
          h2: "22px",
          h3: "20px",
          h4: "18px",
          h5: "16px",
          h6: "14px",
        });
      } else if (width <= 745) {
        setScreenStyles({
          width: "375px",
          h1: "32px",
          h2: "28px",
          h3: "20px",
          h4: "18px",
          h5: "16px",
          h6: "14px",
        });
      } else {
        // 기본값
        setScreenStyles({
          width: "375px",
          h1: "32px",
          h2: "28px",
          h3: "20px",
          h4: "18px",
          h5: "16px",
          h6: "14px",
        });
      }
    };

    window.addEventListener("resize", updateStylesBasedOnWidth);
    updateStylesBasedOnWidth(); // 초기 실행 시 사이즈에 따라 스타일 설정

    return () => window.removeEventListener("resize", updateStylesBasedOnWidth);
  }, [setScreenStyles]);

  return null;
};

export default ScreenSizeObserver;
