import React, { useState } from "react";
import { themeColorsState } from "../../stores/theme_colors_state";
import { useRecoilValue } from "recoil";

const CustomTooltip = ({ position, size = 16, text }) => {
  const colorStyles = useRecoilValue(themeColorsState);
  const [isVisible, setIsVisible] = useState(false);

  const getTooltipStyle = () => {
    if (position === "top") {
      return {
        position: "absolute",
        bottom: "100%",
        left: "50%",
        transform: "translateX(-10%)",
        marginBottom: "8px",
      };
    } else if (position === "right") {
      return {
        position: "absolute",
        top: "50%",
        left: "100%",
        transform: "translateY(-50%)",
        marginLeft: "8px",
      };
    } else if (position === "bottom") {
      return {
        position: "absolute",
        top: "100%",
        left: "50%",
        transform: "translateX(-50%)",
        marginTop: "8px",
      };
    } else if (position === "left") {
      return {
        position: "absolute",
        top: "50%",
        right: "100%",
        transform: "translateY(-50%)",
        marginRight: "8px",
      };
    } else {
      return {
        position: "absolute",
        bottom: "100%",
        left: "50%",
        transform: "translateX(-10%)",
        marginBottom: "8px",
      };
    }
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <div
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: "50%",
          border: "1px solid #49454f",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          color: "#49454f",
          fontWeight: "bold",
          fontSize: "13px",
        }}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        i
      </div>
      {isVisible && (
        <div
          style={{
            ...getTooltipStyle(),
            padding: "8px 12px",
            backgroundColor: colorStyles.main,
            color: "white",
            fontSize: "10px",
            borderRadius: "4px",
            whiteSpace: "nowrap",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;
