import { useRecoilValue } from "recoil";
import { screenStylesState } from "../../stores/screen_styles_state";
import { themeColorsState } from "../../stores/theme_colors_state";
import { IoCloseOutline } from "react-icons/io5";
import CustomTooltip from "../../components/custom_tooltip/custom_tooltip";
import CustomProgressBar from "../../components/custom_progress_bar/custom_progress_bar";
import { useEffect, useState } from "react";
import StepRegion from "../sell_steps/step_region";
import StepOptions from "../sell_steps/step_options";
import StepName from "../sell_steps/step_name";
import StepType from "../sell_steps/step_type";
import StepPath from "../sell_steps/step_path";
import StepPrice from "../sell_steps/step_price";
import StepPhone from "../sell_steps/step_phone";
import StepTime from "../sell_steps/step_time";
import StepDocuments from "../sell_steps/step_documents";
import StepRef from "../sell_steps/step_ref";
import { uploadToS3 } from "../../service/aws.js";
import { simplifyAddress } from "../../utils/area_updator.js";

const SellRealEstatePanel = ({
  data,
  presaleData,
  mapService,
  setShowSellPanel,
}) => {
  const screenStyles = useRecoilValue(screenStylesState);
  const colorStyles = useRecoilValue(themeColorsState);

  const [currentStep, setCurrentStep] = useState(1);

  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [progressState, setProgressState] = useState(10);

  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  const [isReservation, setIsReservation] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const TOTAL_STEPS = 9;

  const [newOrder, setNewOrder] = useState({
    area: [],
    presale_id: "",
    presale_name: "",
    dong: "",
    ho: "",
    house_type: "",
    get_path: "",
    options: [],
    hope_price: 0,
    phone: "",
    possible_time: "",
    documents: [],
    etc: "",
  });

  useEffect(() => {
    if (data) {
      const area = data.hssply_adres.split(" ").slice(0, 3);
      const house_nm = data.house_nm;
      const id = data.id;
      area[0] = simplifyAddress(area[0]);
      handleDataChange("area", area);
      handleDataChange("presale_name", house_nm);
      handleDataChange("presale_id", id);
      setCurrentStep(3);
      setProgressState(30);
      setIsReservation(false);
      setIsActive(false);
      if (presaleData?.id) {
        handleDataChange("dong", presaleData.dong);
        handleDataChange("ho", presaleData.ho);
        handleDataChange("house_type", presaleData.house_type);
      } else {
        setIsReservation(true);
      }
    } else {
      setCurrentStep(1);
      setProgressState(10);
      setIsReservation(true);
      setIsActive(true);
    }

    setIsLoading(false);
  }, [data, presaleData]);

  const handleDataChange = (key, value) => {
    setNewOrder((prevOrder) => ({
      ...prevOrder,
      [key]: value,
    }));
  };

  const handleCloseButtonClick = () => {
    setProgressState(10);
    setCurrentStep(1);
    setShowSellPanel(false);
  };

  const handleNextStep = () => {
    if (isNextEnabled) {
      setCurrentStep(currentStep + 1);
      setProgressState((prev) => prev + 10);
      setIsNextEnabled(false); // Reset button state for next step
    }
  };

  const handlePreviousStep = () => {
    setProgressState((prev) => prev - 10);
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async () => {
    // console.log("서버에 보낼 데이터: ", newOrder);
    if (!newOrder.agree) {
      alert("개인정보 수집 및 이용에 동의해주세요");
      return;
    }
    const paths = [];
    const handleUpload = async () => {
      setIsUploading(true); // 로딩 시작
      try {
        for (const doc of newOrder.documents) {
          if (doc.file) {
            // `doc.file`이 존재하는 경우에만 S3에 업로드
            const filePath = await uploadToS3(doc.file, doc.name);
            paths.push(filePath);
          }
        }
        newOrder.save_path = paths;
        delete newOrder.documents;
      } catch (error) {
        console.error("업로드 중 오류 발생", error);
      }
    };
    //첨부자료 먼저 s3에 업로드
    await handleUpload();
    const res = await mapService.saveSellRealEstateOrder(newOrder);
    if (res.result) {
      alert(res.result);
      setIsUploading(false);
      setShowSellPanel(false);
    } else {
      alert(res.error);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <StepRegion
            data={newOrder["area"]}
            onDataChange={handleDataChange}
            setIsNextEnabled={setIsNextEnabled}
            isActive={isActive}
          />
        );
      case 2:
        return (
          <StepName
            _name={newOrder["presale_name"]}
            id={newOrder["presale_id"]}
            area={newOrder["area"]}
            onDataChange={handleDataChange}
            setIsNextEnabled={setIsNextEnabled}
            mapService={mapService}
            isActive={isActive}
          />
        );
      case 3:
        return (
          <StepType
            data={newOrder}
            presaleId={newOrder["presale_id"]}
            onDataChange={handleDataChange}
            setIsNextEnabled={setIsNextEnabled}
            mapService={mapService}
            isActive={isReservation}
          />
        );
      // case 4:
      //   return (
      //     <StepPath
      //       data={newOrder["get_path"]}
      //       onDataChange={handleDataChange}
      //       setIsNextEnabled={setIsNextEnabled}
      //     />
      //   );
      case 4:
        return (
          <StepOptions
            data={newOrder["options"]}
            onDataChange={handleDataChange}
            setIsNextEnabled={setIsNextEnabled}
          />
        );
      case 5:
        return (
          <StepPrice
            data={newOrder["hope_price"]}
            onDataChange={handleDataChange}
            setIsNextEnabled={setIsNextEnabled}
          />
        );
      case 6:
        return (
          <StepPhone
            data={newOrder["phone"]}
            onDataChange={handleDataChange}
            setIsNextEnabled={setIsNextEnabled}
          />
        );
      case 7:
        return (
          <StepTime
            data={newOrder["possible_time"]}
            onDataChange={handleDataChange}
            setIsNextEnabled={setIsNextEnabled}
          />
        );

      case 8:
        return (
          <StepDocuments
            data={newOrder["documents"]}
            onDataChange={handleDataChange}
            setIsNextEnabled={setIsNextEnabled}
          />
        );
      case 9:
        return (
          <StepRef
            data={newOrder["etc"]} //
            onDataChange={handleDataChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className="container"
      style={{
        display: "flex",
        flexDirection: "column",
        width: screenStyles.width,
        height: "calc(var(--vh, 1vh) * 100)",
        backgroundColor: "white",
        position: "fixed",
        left: "50%",
        transform: "translate(-50%, 0)",
      }}
    >
      <section
        className="header"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: screenStyles.width,
          height: "47px",
          backgroundColor: "white",
          borderBottom: `1px ${colorStyles.dark_gray} solid`,
        }}
      >
        <div
          className="title"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            fontWeight: 700,
            marginRight: "2px",
          }}
        >
          매도하기
        </div>
        <div
          style={{
            display: "flex",
            width: "10px",
            height: "70%",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <CustomTooltip
            position={"bottom"}
            size={12}
            text={
              "매물 확인 및 매도의사 확인을 위해 공인중개사와 통화 후 등록이 진행됩니다."
            }
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: "5px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={handleCloseButtonClick}
        >
          <IoCloseOutline
            style={{
              width: "37px",
              height: "37px",
              strokeWidth: 1,
            }}
          />
        </div>
      </section>
      <section style={{ margin: "40px 10px" }}>
        <CustomProgressBar step={currentStep} totalSteps={TOTAL_STEPS} />
      </section>

      <section
        className="main"
        style={{
          flex: 1,
          overflowY: "scroll", // 세로 스크롤만 활성화
          scrollbarWidth: "none", // Firefox에서 스크롤 바 숨기기
          msOverflowStyle: "none", // IE 및 Edge에서 스크롤 바 숨기기
          "&::WebkitScrollbar": { display: "none" },
        }}
      >
        {!isLoading && renderStep()}
      </section>
      <section style={{ display: "flex" }}>
        {currentStep > 1 && currentStep < TOTAL_STEPS ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "50px",
              padding: "20px",
              justifyContent: "center",
              gap: 10,
            }}
          >
            <button
              style={{
                backgroundColor: "grey",
                color: "white",
                border: "none",
                width: "90%",
                height: "90%",
                borderRadius: "5px",
                fontWeight: 500,
                fontSize: "20px",
                flex: 3,
                cursor: "pointer",
              }}
              onClick={handlePreviousStep}
            >
              이전
            </button>
            <button
              onClick={handleNextStep}
              disabled={!isNextEnabled}
              style={{
                backgroundColor: isNextEnabled
                  ? colorStyles.main
                  : colorStyles.dark_gray,
                color: "white",
                border: "none",
                width: "90%",
                height: "90%",
                borderRadius: "5px",
                fontWeight: 500,
                fontSize: "20px",
                flex: 7,
                cursor: "pointer",
              }}
            >
              다음
            </button>
          </div>
        ) : currentStep === TOTAL_STEPS ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "50px",
              padding: "20px",
              justifyContent: "center",
              gap: 10,
            }}
          >
            <button
              style={{
                backgroundColor: "grey",
                color: "white",
                border: "none",
                width: "90%",
                height: "90%",
                borderRadius: "5px",
                fontWeight: 500,
                fontSize: "20px",
                flex: 3,
                cursor: "pointer",
              }}
              onClick={handlePreviousStep}
            >
              이전
            </button>
            <button
              onClick={handleSubmit}
              style={{
                backgroundColor: colorStyles.main,
                color: "white",
                border: "none",
                width: "90%",
                height: "90%",
                borderRadius: "5px",
                fontWeight: 500,
                fontSize: "20px",
                flex: 7,
                cursor: "pointer",
              }}
            >
              매도접수
            </button>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "50px",
              padding: "20px",
              justifyContent: "center",
            }}
          >
            <button
              onClick={handleNextStep}
              disabled={!isNextEnabled}
              style={{
                backgroundColor: isNextEnabled
                  ? colorStyles.main
                  : colorStyles.dark_gray,
                color: "white",
                border: "none",
                width: "90%",
                height: "90%",
                borderRadius: "5px",
                fontWeight: 500,
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              다음
            </button>
          </div>
        )}
      </section>
      {isUploading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: "50%",
            transform: "translate(-50%, 0)",
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="spinner"
            style={{
              border: "6px solid rgba(255, 255, 255, 0.3)",
              borderTop: "6px solid white",
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              animation: "spin 1s linear infinite",
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default SellRealEstatePanel;
