export default class MapService {
  constructor(http) {
    this.http = http;
  }

  async getAreaInfo(obj) {
    return await this.http.fetch("/map/area", {
      method: "POST",
      body: JSON.stringify({ obj }),
    });
  }

  async getSearchInfo(term) {
    return await this.http.fetch(`/map/search?term=${term}`, {
      method: "GET",
    });
  }

  async getObjectsInBounds(req) {
    return await this.http.fetch(`/map/objects?${req}`, {
      method: "GET",
    });
  }

  async getAptInfoDetail(id) {
    return await this.http.fetch(`/map/detail?id=${id}`, {
      method: "GET",
    });
  }

  async getAroundAptInfo(id) {
    return await this.http.fetch(`/map/around?id=${id}`, {
      method: "GET",
    });
  }

  async getPresaleList(area) {
    return await this.http.fetch(`/map/presalelist?area=${area}`, {
      method: "GET",
    });
  }

  async getTypeList(id) {
    return await this.http.fetch(`/map/typelist?id=${id}`, {
      method: "GET",
    });
  }

  async getCoordinatesByAddresses(addresses) {
    return await this.http.fetch(`/map/coordinates`, {
      method: "POST",
      body: JSON.stringify({ addresses }),
    });
  }

  async saveSellRealEstateOrder(order) {
    return await this.http.fetch("/map/sell", {
      method: "POST",
      body: JSON.stringify({ order }),
    });
  }

  async saveBuyRealEstateOrder(order) {
    return await this.http.fetch("/map/buy", {
      method: "POST",
      body: JSON.stringify({ order }),
    });
  }

  async getPreSaleListbyId(id) {
    return await this.http.fetch(`/map/presalelist/id?value=${id}`, {
      method: "GET",
    });
  }
}
