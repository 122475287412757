// 변환 상수
const PYEONG_TO_SQUARE_METER = 3.305785;

// 제곱미터를 평으로 변환하는 함수
export function squareMeterToPyeong(squareMeter) {
  if (typeof squareMeter !== "number" || isNaN(squareMeter)) {
    throw new Error("입력값은 숫자여야 합니다.");
  }

  if (squareMeter < 0) {
    throw new Error("제곱미터는 음수일 수 없습니다.");
  }

  const pyeong = squareMeter / PYEONG_TO_SQUARE_METER;
  return Math.round(pyeong);
}

// 평을 제곱미터로 변환하는 함수
export function pyeongToSquareMeter(pyeong) {
  if (typeof pyeong !== "number" || isNaN(pyeong)) {
    throw new Error("입력값은 숫자여야 합니다.");
  }

  if (pyeong < 0) {
    throw new Error("평은 음수일 수 없습니다.");
  }

  const squareMeter = pyeong * PYEONG_TO_SQUARE_METER;
  return Math.round(squareMeter);
}

// 제곱미터당 가격을 계산하는 함수
export function calculatePricePerSquareMeter(area, price) {
  if (
    typeof area !== "number" ||
    typeof price !== "number" ||
    isNaN(area) ||
    isNaN(price)
  ) {
    throw new Error("면적과 가격은 모두 숫자여야 합니다.");
  }

  if (area <= 0 || price < 0) {
    throw new Error("면적은 양수여야 하며, 가격은 0 이상이어야 합니다.");
  }

  const pricePerSquareMeter = price / area;
  return Math.round(pricePerSquareMeter);
}

// 평당 가격을 계산하는 함수
export function calculatePricePerPyeong(area, price) {
  if (
    typeof area !== "number" ||
    typeof price !== "number" ||
    isNaN(area) ||
    isNaN(price)
  ) {
    throw new Error("면적과 가격은 모두 숫자여야 합니다.");
  }

  if (area <= 0 || price < 0) {
    throw new Error("면적은 양수여야 하며, 가격은 0 이상이어야 합니다.");
  }

  const pricePerPyeong = price / area;
  return Math.round(pricePerPyeong);
}

// 평 또는 제곱미터와 가격을 입력받아 평당 또는 제곱미터당 가격을 계산하는 함수
export function calculateUnitPrice(area, price, unit = "meter") {
  if (
    typeof area !== "number" ||
    typeof price !== "number" ||
    isNaN(area) ||
    isNaN(price)
  ) {
    throw new Error("면적과 가격은 모두 숫자여야 합니다.");
  }

  if (area <= 0 || price < 0) {
    throw new Error("면적은 양수여야 하며, 가격은 0 이상이어야 합니다.");
  }

  if (unit !== "meter" && unit !== "pyeong") {
    throw new Error('단위는 "squareMeter" 또는 "pyeong"이어야 합니다.');
  }

  if (unit === "meter") {
    return {
      pricePerSquareMeter: calculatePricePerSquareMeter(area, price),
      pricePerPyeong: calculatePricePerPyeong(squareMeterToPyeong(area), price),
    };
  } else {
    return {
      pricePerSquareMeter: calculatePricePerSquareMeter(
        pyeongToSquareMeter(area),
        price
      ),
      pricePerPyeong: calculatePricePerPyeong(area, price),
    };
  }
}
