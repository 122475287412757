import React, { useState, useRef, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";
import { IoCameraOutline } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiOutlineFilePdf } from "react-icons/ai";

const StepDocuments = ({ data, onDataChange, setIsNextEnabled }) => {
  const colorStyles = useRecoilValue(themeColorsState);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedCount, setUploadedCount] = useState(0);
  const fileInputRef = useRef(null);
  const MAX_FILES = 10;

  useEffect(() => {
    setIsNextEnabled(true);
    if (data?.length > 0) {
      setSelectedFiles(data);
      setUploadedCount(data.length);
    }
  }, []);

  useEffect(() => {
    onDataChange("documents", selectedFiles);
  }, [selectedFiles]);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    if (selectedFiles.length + files.length > MAX_FILES) {
      alert(`최대 ${MAX_FILES}개의 파일만 선택할 수 있습니다.`);
      return;
    }
    const newFiles = files.map((file) => ({
      name: file.name,
      type: file.type,
      size: file.size,
      lastModified: file.lastModified,
      file: file,
      dataUrl: file.type.startsWith("image/")
        ? URL.createObjectURL(file)
        : null,
    }));
    setSelectedFiles((prev) => [...prev, ...newFiles]);
    setUploadedCount((prev) => prev + files.length);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prev) => {
      const newFiles = [...prev];
      URL.revokeObjectURL(newFiles[index].dataUrl);
      newFiles.splice(index, 1);
      return newFiles;
    });
    setUploadedCount((prev) => prev - 1);
  };

  // const handleUpload = async () => {
  //   // S3 업로드 로직 구현
  //   console.log("S3에 업로드 중...");

  //   // DB에 경로 저장 로직 구현
  //   console.log("DB에 경로 저장 중...");

  //   alert("업로드 완료!");
  // };

  const renderFilePreview = (file, index) => {
    if (file.type.startsWith("image/")) {
      return (
        <img
          src={file.dataUrl}
          alt={`Preview ${index}`}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      );
    } else if (file.type === "application/pdf") {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AiOutlineFilePdf size={48} color="#FF0000" />
          <span
            style={{
              width: "100%",
              fontSize: "11px",
              fontWeight: 600,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {file.name}
          </span>
        </div>
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "20px 10px",
        gap: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
          gap: "8px",
        }}
      >
        <span style={{ fontSize: "22px", fontWeight: 600 }}>
          확인가능한 서류를 첨부해주세요
        </span>
        <span style={{ fontSize: "16px", color: colorStyles.gray }}>
          {"당첨 문자, 청약홈 당첨 페이지, 계약서 등의 서류 "}
        </span>
      </div>
      <div style={{ display: "flex", padding: "10px" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "18px",
          }}
        >
          <div
            style={{
              width: "90px",
              height: "90px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => fileInputRef.current.click()}
          >
            <IoCameraOutline size={40} style={{ flex: 3 }} />
            <span style={{ fontSize: "14px", flex: 1 }}>
              {uploadedCount} / {MAX_FILES}
            </span>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileSelect}
              multiple
              accept="image/*,.pdf"
              style={{ display: "none" }}
            />
          </div>
          {selectedFiles?.map((file, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                width: "90px",
                height: "90px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            >
              {renderFilePreview(file, index)}
              <IoIosCloseCircleOutline
                size={18}
                style={{
                  position: "absolute",
                  top: "4px",
                  right: "4px",
                  backgroundColor: "#f44336",
                  color: "white",
                  border: "none",
                  borderRadius: "50%",
                  padding: "1px",
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveFile(index)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StepDocuments;
