import { useEffect, useState } from "react";
import CustomSelect from "../custom_select/custom_select";

const StepName = ({
  _name,
  id,
  area,
  mapService,
  onDataChange,
  setIsNextEnabled,
  isActive,
}) => {
  const [presaleOptions, setPresaleOptions] = useState([]);
  const [otherInput, setOtherInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPresaleOptions = async () => {
      setIsLoading(true);
      try {
        const response = await mapService.getPresaleList(area.join(","));
        setPresaleOptions(response.result);
        // setPresaleOptions([...response.result, { id: "other", name: "기타" }]);
      } catch (err) {
        setError("분양권 정보를 불러오는데 실패했습니다.");
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPresaleOptions();
  }, [area]);

  useEffect(() => {
    if (_name) {
      setIsNextEnabled(true);
    }
  }, []);

  const handleSelectChange = (selected) => {
    if (selected.name !== "other") {
      onDataChange("presale_name", selected.name);
      onDataChange("presale_id", selected.id);
      setIsNextEnabled(true);
    } else {
      setIsNextEnabled(false);
    }
  };

  if (isLoading) return <div>로딩 중...</div>;
  if (error) return <div>{error}</div>;
  return (
    <div>
      <h3 style={{ paddingLeft: "10px", fontSize: "22px" }}>
        보유중인 분양권을 선택해주세요
      </h3>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "60px",
        }}
      >
        <div style={{ width: "95%" }}>
          {presaleOptions.length > 0 ? (
            isActive ? (
              <CustomSelect
                placeholder="건물이름 선택"
                options={presaleOptions}
                onChange={handleSelectChange}
                inputValue={{ id: id, name: _name }}
              />
            ) : (
              <input
                style={{
                  width: "90%",
                  padding: "15px 15px 15px 10px",
                  fontSize: "16px",
                }}
                value={_name || ""}
                disabled={true}
              />
            )
          ) : (
            <input
              style={{
                width: "90%",
                padding: "15px 15px 15px 10px",
                fontSize: "16px",
              }}
              value={_name || ""}
              disabled={isActive ? false : true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StepName;
