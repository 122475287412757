import { useRef, useState } from "react";
import styles from "./custom_carousel.module.css";

const CustomCarousel = ({ subList }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isCarouselDragging, setIsCarouselDragging] = useState(false);
  const carouselRef = useRef(null);
  const carouselStartX = useRef(null);
  const touchStartX = useRef(null);

  const itemCount = 4;
  const totalSlides = Math.ceil(subList.length / itemCount);

  const initHouseType = (input) => {
    const typeString = input.match(/[a-zA-Z]+/g);
    const typeArea = input.split(".")[0];
    return typeString ? `${parseInt(typeArea)}${typeString}` : null;
  };

  const initFloor = (input) => {
    if (!input) {
      return;
    }
    const hoString = String(input);
    if (hoString.length > 2) {
      const limit = hoString.length - 2;
      const floor = hoString.slice(0, limit);
      return floor;
    }
  };

  const handleCarouselDragStart = (e) => {
    carouselStartX.current = e.pageX - carouselRef.current.offsetLeft;
    setIsCarouselDragging(true);
  };

  const handleCarouselDragMove = (e) => {
    if (!isCarouselDragging) return;
    e.preventDefault();
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - carouselStartX.current) * 3;
    // if (walk < -100) {
    //   handleNextSlide();
    //   setIsCarouselDragging(false);
    // } else if (walk > 100) {
    //   handlePrevSlide();
    //   setIsCarouselDragging(false);
    // }
    handleSwipe(walk);
  };

  const handleTouchMove = (e) => {
    if (!touchStartX.current) return;
    e.preventDefault();
    const touchEndX = e.touches[0].clientX;
    const walk = (touchEndX - touchStartX.current) * 2;
    handleSwipe(walk);
  };

  const handleCarouselDragEnd = () => {
    setIsCarouselDragging(false);
  };

  // 터치 이벤트 핸들러
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
    setIsCarouselDragging(true);
  };

  const handleTouchEnd = () => {
    touchStartX.current = null;
    setIsCarouselDragging(false);
  };

  // 공통 스와이프 처리 함수
  const handleSwipe = (walk) => {
    if (walk < -50) {
      handleNextSlide();
      setIsCarouselDragging(false);
      touchStartX.current = null;
    } else if (walk > 50) {
      handlePrevSlide();
      setIsCarouselDragging(false);
      touchStartX.current = null;
    }
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
  };

  const renderCarouselItems = () => {
    const items = [];
    for (let i = 0; i < subList.length; i += itemCount) {
      items.push(
        <div key={i} className={styles.carouselSlide}>
          {subList.slice(i, i + itemCount).map((subItem, index) =>
            subItem.trade_type === "sell" ? (
              <div key={index} className={styles.subItem}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <span className={styles.subItemTitle}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #0274B4",
                        color: "#0274B4",
                        fontSize: "12px",
                        width: "32px",
                        height: "20px",
                      }}
                    >
                      매도
                    </span>
                    {`P ${subItem.hope_price.toLocaleString()}만원`}
                  </span>
                  <span style={{ fontSize: "14px" }}>
                    {`${initHouseType(subItem.house_type)} | ${
                      subItem.dong
                    }동 ${initFloor(subItem.ho)}층 
                  `}
                  </span>
                </div>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                    fontSize: "14px",
                  }}
                >
                  {subItem.options.length > 0
                    ? subItem.options.join(", ")
                    : "옵션없음"}
                </div>
              </div>
            ) : (
              <div key={index} className={styles.subItem}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <span className={styles.subItemTitle}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #0274B4",
                        color: "#0274B4",
                        fontSize: "12px",
                        width: "32px",
                        height: "20px",
                      }}
                    >
                      매수
                    </span>
                    {`P ${subItem.hope_price.toLocaleString()}만원`}
                  </span>
                  <span style={{ fontSize: "14px" }}>
                    {`${
                      subItem.house_type
                        ? initHouseType(subItem.house_type)
                        : "정보없음"
                    } | ${subItem.dong}동 ${
                      subItem.ho ? initFloor(subItem.ho) : subItem.floor
                    }층 
                  `}
                  </span>
                </div>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                    fontSize: "14px",
                  }}
                >
                  {subItem.options?.length > 0
                    ? subItem.options.join(", ")
                    : "옵션없음"}
                </div>
              </div>
            )
          )}
        </div>
      );
    }
    return items;
  };

  return (
    <div
      className={styles.carouselContainer}
      ref={carouselRef}
      onMouseDown={handleCarouselDragStart}
      onMouseMove={handleCarouselDragMove}
      onMouseUp={handleCarouselDragEnd}
      onMouseLeave={handleCarouselDragEnd}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        className={styles.carouselWrapper}
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
          transition: isCarouselDragging
            ? "none"
            : "transform 0.3s ease-in-out",
        }}
      >
        {renderCarouselItems()}
      </div>
      <div className={styles.pageIndicators}>
        {Array.from({ length: totalSlides }).map((_, index) => (
          <div
            key={index}
            className={`${styles.pageIndicator} ${
              index === currentSlide ? styles.activePage : ""
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;
