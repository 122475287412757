import React from "react";
import styles from "./sell_real_estate_button.module.css";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";

const SellRealEstateButton = ({ onClick, handleClick }) => {
  const colorStyles = useRecoilValue(themeColorsState);
  const doSell = () => {
    handleClick(true);
    onClick();
  };

  return (
    <div className={styles.container}>
      <button
        onClick={doSell}
        className={styles.button}
        style={{
          borderColor: colorStyles.main,
          backgroundColor: colorStyles.main,
        }}
      >
        {`+ 매도하기`}
      </button>
    </div>
  );
};

export default SellRealEstateButton;
