import { useEffect, useState } from "react";
import HorizontalLine from "../seperators/horizontal_line";
import styles from "./sale_status_section.module.css";
import CustomTooltip from "../custom_tooltip/custom_tooltip";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";

const SaleStatusSection = ({ initData, handleOptionSingleClick, isReset }) => {
  const colorStyles = useRecoilValue(themeColorsState);
  const [optionTitle, setOptionTitle] = useState("분양상태");
  const [selectedButton, setSelectedButton] = useState("분양상태");
  useEffect(() => {
    if (initData) {
      setOptionTitle(initData);
      setSelectedButton(initData);
    } else {
      setSelectedButton(null);
    }
  }, [initData]);

  const handleButtonClick = (newTitle) => {
    setOptionTitle(newTitle);
    setSelectedButton(newTitle); // 클릭된 버튼 업데이트
  };

  const handleReset = () => {
    setSelectedButton(null);
  };

  useEffect(() => {
    handleOptionSingleClick("분양상태", optionTitle);
  }, [optionTitle]);

  useEffect(() => {
    isReset && handleReset();
  }, [isReset]);

  // console.log(selectedButton);

  return (
    <>
      <h4 className={styles.categoryTitle}>{"분양 상태"}</h4>
      <HorizontalLine size={1} color={colorStyles.dark_gray} />
      <div className={styles.categoryOptions}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            fontWeight: 600,
          }}
        >
          <span style={{ marginRight: "5px" }}>{"분양 상태"}</span>
        </div>
        <HorizontalLine size={1} color={colorStyles.dark_gray} />
        <div
          className={styles.buttonSection}
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: "20px",
            gap: "10px",
          }}
        >
          <>
            <button
              key={"future"}
              onClick={() => handleButtonClick("future")}
              className={styles.optionButton}
              style={{
                color: selectedButton === "future" ? colorStyles.main : "",
                borderColor:
                  selectedButton === "future" ? colorStyles.main : "",
                borderWidth: selectedButton === "future" ? "2px" : "",
                fontWeight: selectedButton === "future" ? "bold" : "",
              }}
            >
              분양전
            </button>
            <button
              key={"now"}
              onClick={() => handleButtonClick("now")}
              className={styles.optionButton}
              style={{
                color: selectedButton === "now" ? colorStyles.main : "",
                borderColor: selectedButton === "now" ? colorStyles.main : "",
                borderWidth: selectedButton === "now" ? "2px" : "",
                fontWeight: selectedButton === "now" ? "bold" : "",
              }}
            >
              분양중
            </button>
            <button
              key={"past"}
              onClick={() => handleButtonClick("past")}
              className={styles.optionButton}
              style={{
                color: selectedButton === "past" ? colorStyles.main : "",
                borderColor: selectedButton === "past" ? colorStyles.main : "",
                borderWidth: selectedButton === "past" ? "2px" : "",
                fontWeight: selectedButton === "past" ? "bold" : "",
              }}
            >
              분양완료
            </button>
          </>
        </div>
      </div>
    </>
  );
};

export default SaleStatusSection;
