import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import config from "../config.js";

// S3 설정
const s3 = new AWS.S3({
  accessKeyId: config.s3.access_key, // S3 Access Key
  secretAccessKey: config.s3.secret_key, // S3 Secret Key
  region: config.s3.region, // S3 Bucket Region
  signatureVersion: config.s3.version,
});

// 현재 날짜를 '년/월/일' 형식으로 반환하는 함수
const getFormattedDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}/${month}/${day}`;
};

// S3에 파일 업로드 함수
export const uploadToS3 = async (file, fileName) => {
  const datePath = getFormattedDate(); // '년/월/일' 경로 생성
  const randomName = uuidv4(); // 랜덤 이름 생성
  const filePath = `${datePath}/${randomName}-${fileName}`; // 파일 경로
  // console.log(config);

  const params = {
    Bucket: config.s3.bucket_name, // S3 버킷 이름
    Key: filePath,
    Body: file, // 파일 내용
    ContentType: file.type, // 파일 타입
  };

  // S3에 파일 업로드
  await s3.upload(params).promise();
  return filePath;
};
