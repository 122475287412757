const HorizontalLine = ({ style, size, color }) => {
  return (
    <>
      <div
        style={{
          ...style,
          width: "100%",
          height: `${size}px`,
          backgroundColor: `${color}`,
        }}
      />
    </>
  );
};

export default HorizontalLine;
