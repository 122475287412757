import React, { useCallback, useEffect, useRef, useState } from "react";
import { SlRefresh } from "react-icons/sl";
import styles from "./category_button_bar.module.css";
import RegionCategorySection from "../region_category_section/region_category_section";
import CostSlider from "../cost_slider/cost_slider";
import HouseTypeSection from "../house_type_section/house_type_section";
import SpaceSizeSection from "../space_size_section/space_size_section";
import HouseholdSection from "../household_section/household_section";
import NumberofHouseSection from "../number_of_house_section/number_of_house_section";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";
import SaleStatusSection from "../sale_status_section/sale_status_section";

const CategoryButtonBar = ({
  categories,
  onFilterSearch,
  setShowCategoryOptions,
}) => {
  const colorStyles = useRecoilValue(themeColorsState);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [showOptions, setShowOptions] = useState(false);
  const [temporarySelection, setTemporarySelection] = useState({});
  const [isReset, setIsReset] = useState(false);
  const optionsContainerRef = useRef();
  const categoryRefs = useRef({});

  const categoryButtonsRef = useRef(null);

  useEffect(() => {
    const slider = categoryButtonsRef.current;
    let isDown = false;
    let startX;
    let scrollLeft;

    const handleMouseDown = (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const handleMouseLeave = () => {
      isDown = false;
      slider.classList.remove("active");
    };

    const handleMouseUp = () => {
      isDown = false;
      slider.classList.remove("active");
    };

    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2; // 드래그 속도 조정 (값을 조정해 원하는 속도를 설정)
      slider.scrollLeft = scrollLeft - walk;
    };

    const handleTouchStart = (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.touches[0].clientX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const handleTouchEnd = () => {
      isDown = false;
      slider.classList.remove("active");
    };

    const handleTouchMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.touches[0].clientX - slider.offsetLeft;
      const walk = (x - startX) * 2; // 드래그 속도 조정 (값을 조정해 원하는 속도를 설정)
      slider.scrollLeft = scrollLeft - walk;
    };

    slider.addEventListener("mousedown", handleMouseDown);
    slider.addEventListener("mouseleave", handleMouseLeave);
    slider.addEventListener("mouseup", handleMouseUp);
    slider.addEventListener("mousemove", handleMouseMove);
    slider.addEventListener("touchstart", handleTouchStart);
    slider.addEventListener("touchend", handleTouchEnd);
    slider.addEventListener("touchmove", handleTouchMove);

    return () => {
      slider.removeEventListener("mousedown", handleMouseDown);
      slider.removeEventListener("mouseleave", handleMouseLeave);
      slider.removeEventListener("mouseup", handleMouseUp);
      slider.removeEventListener("mousemove", handleMouseMove);
      slider.removeEventListener("touchstart", handleTouchStart);
      slider.removeEventListener("touchend", handleTouchEnd);
      slider.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  /**
   * 상위 카테고리에 표시되는 값들
   */
  // const categories = {
  //   지역: [],
  //   가격: [],
  //   건물종류: [],
  //   면적: [],
  //   세대수: [],
  //   주택수: [],
  // };

  const defaultCategories = {
    지역: [],
    가격: [],
    건물종류: [],
    면적: [],
    세대수: [],
    주택수: null,
    분양상태: null,
  };

  // categories가 변경되거나 초기화될 때 selectedOptions를 초기화
  useEffect(() => {
    if (categories) {
      setSelectedOptions(categories);
      setTemporarySelection(categories);
    }
  }, [categories, selectedOptions]);

  /**
   * @param {String} category
   * 상위 카테고리를 클릭 했을 경우 동작하는 함수
   * 클릭한 카테고리 이름을 변수에 저장(setSElectedCategory)해서 클릭했을때
   * 경계선이 강조되도록 함
   * 이미 선택한 옵션들이 있을경우가 있어서, 임시 변수(setTemporarySelection)에 저장함
   * 이렇게 하면 꼭 저장버튼을 눌러야 변경한 값들이 반영 됨
   * 하단에서 Option창이 나오게 함
   */
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setTemporarySelection(selectedOptions);
    setShowOptions(true);
    setShowCategoryOptions(true);
  };

  /**
   * @param {String}} category
   * @param {List[String]} option
   * 복수 버튼 클릭시 임시저장변수에 저장하는 함수
   */
  const handleOptionClick = (category, option) => {
    setTemporarySelection((prev) => ({
      ...prev,
      [category]: prev[category]?.includes(option)
        ? prev[category].filter((opt) => opt !== option)
        : [...(prev[category] || []), option],
    }));
  };

  /**
   * @param {String}} category
   * @param {List[String]} option
   * 단일 버튼 클릭시 임시저장변수에 저장하는 함수
   */
  const handleOptionSingleClick = (category, option) => {
    // console.log(option);

    setTemporarySelection((prev) => ({
      ...prev,
      [category]: option,
    }));
  };

  // const handlePriceChange = (event, newValue) => {
  //   setPriceRange(newValue);
  //   setPriceRangeInitialized(true);
  // };

  /**
   * @param {String}} category
   * @param {List[String]} option
   * 슬라이더를 변경할때 변경한 값을 임시변수에 저장하는 함수
   */
  const handleSliderChange = (category, option) => {
    setTemporarySelection((prev) => ({
      ...prev,
      [category]: option,
    }));
    // console.log(temporarySelection);
  };

  /**
   * 옵션들을 선택할 때마다 저장한 임시변수의 값들을 저장(setSelectedOptions)
   * 이때 가격 카테고리에서 특정 값이면 저장하지 않음(초기화)
   */
  const handleApply = () => {
    const updatedOptions = {
      ...temporarySelection,
    };

    // if (updatedOptions) {
    //   if (
    //     updatedOptions["가격"][0] === 0 &&
    //     updatedOptions["가격"][1] === 5000000000
    //   ) {
    //     delete updatedOptions.가격; // 가격을 저장하지 않음
    //   }
    //   if (
    //     updatedOptions["면적"][0] === 0 && //
    //     updatedOptions["면적"][1] === 50
    //   ) {
    //     delete updatedOptions.면적; // 면적을 저장하지 않음
    //   }
    //   if (
    //     updatedOptions["세대수"][0] === 0 && //
    //     updatedOptions["세대수"][1] === 5000
    //   ) {
    //     delete updatedOptions.세대수; // 면적을 저장하지 않음
    //   }
    // }

    // console.log("Apply: ", updatedOptions);

    setSelectedOptions(updatedOptions);
    // console.log("Selected options:", updatedOptions);
    // 초기화 상태 및 모달 닫기
    setShowOptions(false);
    setSelectedCategory(null);
    onFilterSearch(updatedOptions);
  };

  /**
   * 선택된 값 및 가격 범위를 초기값으로 되돌리는 함수
   */
  const handleReset = () => {
    setIsReset(true);
    setTemporarySelection(defaultCategories);
  };

  /**
   * @param {*} event
   * 마우스 클릭을 옵션창 밖에서 할 경우, 옵션창을 닫히게 하는 함수
   * 선택한 카테고리와 임시저장변수에 저장된 값을 제거함
   */
  const handleClickOutside = (event) => {
    if (
      optionsContainerRef.current &&
      !optionsContainerRef.current.contains(event.target) &&
      !event.target.closest("button") &&
      !categoryButtonsRef.current.contains(event.target)
    ) {
      setShowOptions(false);
      setSelectedCategory(null);
      setTemporarySelection(null);
      setShowCategoryOptions(false);
    }
  };

  /**
   * 만약 isReset값이 true면 false로 바꿔 줌
   * handleReset()이 동작하면 isReset이 true가 되고, 하위 컴포넌트들 값이 변경됨
   * 변경 이후에 isReset을 false로 초기화
   */
  useEffect(() => {
    isReset && setIsReset(false);
  }, [isReset]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (showOptions && selectedCategory) {
      const selectedCategoryElement = categoryRefs.current[selectedCategory];
      // console.log(selectedCategoryElement);

      if (selectedCategoryElement) {
        selectedCategoryElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [showOptions, selectedCategory]);

  return (
    <div className={styles.container}>
      <div className={styles.categoryButtons} ref={categoryButtonsRef}>
        <button
          key={"지역"}
          onClick={() => handleCategoryClick("지역")}
          className={styles.button}
          style={{
            color:
              selectedOptions["지역"]?.length > 0 || selectedCategory === "지역"
                ? colorStyles.main
                : "",
            borderColor:
              selectedOptions["지역"]?.length > 0 || selectedCategory === "지역"
                ? colorStyles.main
                : "",
            borderWidth:
              selectedOptions["지역"]?.length > 0 || selectedCategory === "지역"
                ? "2px"
                : "",
            fontWeight:
              selectedOptions["지역"]?.length > 0 || selectedCategory === "지역"
                ? "bold"
                : "",
          }}
        >
          {
            selectedOptions["지역"]
              ? selectedOptions["지역"][0] &&
                !selectedOptions["지역"][1] &&
                !selectedOptions["지역"][2]
                ? selectedOptions["지역"][0]
                : selectedOptions["지역"][0] &&
                  selectedOptions["지역"][1] &&
                  !selectedOptions["지역"][2]
                ? `${selectedOptions["지역"][0]}>${selectedOptions["지역"][1]}`
                : selectedOptions["지역"][0] &&
                  selectedOptions["지역"][1] &&
                  selectedOptions["지역"][2]
                ? `${selectedOptions["지역"][0]}>${selectedOptions["지역"][1]}>${selectedOptions["지역"][2]}`
                : "지역"
              : "지역"
            // `${selectedOptions["지역"][0]}>${selectedOptions["지역"][1]}>${selectedOptions["지역"][2]}`
          }
        </button>
        <button
          key={"가격"}
          onClick={() => handleCategoryClick("가격")}
          className={styles.button}
          style={{
            color:
              selectedCategory === "가격" ||
              (selectedOptions["가격"]?.length > 0 &&
                !(
                  selectedOptions["가격"][0] === 0 &&
                  selectedOptions["가격"][1] === 5000000000
                ))
                ? colorStyles.main // 활성화 상태의 텍스트 색상
                : "", // 기본 텍스트 색상
            borderColor:
              selectedCategory === "가격" ||
              (selectedOptions["가격"]?.length > 0 &&
                !(
                  selectedOptions["가격"][0] === 0 &&
                  selectedOptions["가격"][1] === 5000000000
                ))
                ? colorStyles.main // 활성화 상태의 테두리 색상
                : "", // 기본 테두리 색상
            borderWidth:
              selectedCategory === "가격" ||
              (selectedOptions["가격"]?.length > 0 &&
                !(
                  selectedOptions["가격"][0] === 0 &&
                  selectedOptions["가격"][1] === 5000000000
                ))
                ? "2px"
                : "",
            fontWeight:
              selectedCategory === "가격" ||
              (selectedOptions["가격"]?.length > 0 &&
                !(
                  selectedOptions["가격"][0] === 0 &&
                  selectedOptions["가격"][1] === 5000000000
                ))
                ? "bold"
                : "",
          }}
        >
          <div className="textBox" style={{ display: "flex" }}>
            <div style={{ fontSize: "14px" }}>
              {selectedOptions["가격"]?.length > 0
                ? selectedOptions["가격"][0] === 0 &&
                  selectedOptions["가격"][1] === 5000000000
                  ? ""
                  : selectedOptions["가격타입"]
                : ""}
            </div>
            <div
              style={{
                fontSize:
                  selectedOptions["가격"]?.length > 0
                    ? selectedOptions["가격"][0] === 0 &&
                      selectedOptions["가격"][1] === 5000000000
                      ? ""
                      : "14px"
                    : "",
                marginLeft: selectedOptions["가격"]?.length > 0 ? "" : "4px",
              }}
            >
              {selectedOptions["가격"]?.length > 0
                ? selectedOptions["가격"][0] === 0 &&
                  selectedOptions["가격"][1] === 5000000000
                  ? "가격"
                  : `${(
                      selectedOptions["가격"][0] / 10000
                    ).toLocaleString()}만원 ~ ${(
                      selectedOptions["가격"][1] / 10000
                    ).toLocaleString()}만원`
                : "가격"}
            </div>
          </div>
        </button>
        <button
          key={"건물종류"}
          onClick={() => handleCategoryClick("건물종류")}
          className={styles.button}
          style={{
            color:
              selectedOptions["건물종류"]?.length > 0 ||
              selectedCategory === "건물종류"
                ? colorStyles.main
                : "",
            borderColor:
              selectedOptions["건물종류"]?.length > 0 ||
              selectedCategory === "건물종류"
                ? colorStyles.main
                : "",
            borderWidth:
              selectedOptions["건물종류"]?.length > 0 ||
              selectedCategory === "건물종류"
                ? "2px"
                : "",
            fontWeight:
              selectedOptions["건물종류"]?.length > 0 ||
              selectedCategory === "건물종류"
                ? "bold"
                : "",
          }}
        >
          {selectedOptions["건물종류"]?.length > 0
            ? selectedOptions["건물종류"]
            : "건물종류"}
        </button>
        <button
          key={"면적"}
          onClick={() => handleCategoryClick("면적")}
          className={styles.button}
          style={{
            color:
              selectedCategory === "면적" ||
              (selectedOptions["면적"]?.length > 0 &&
                !(
                  selectedOptions["면적"][0] === 0 &&
                  selectedOptions["면적"][1] === 50
                ))
                ? colorStyles.main // 활성화 상태의 텍스트 색상
                : "", // 기본 텍스트 색상
            borderColor:
              selectedCategory === "면적" ||
              (selectedOptions["면적"]?.length > 0 &&
                !(
                  selectedOptions["면적"][0] === 0 &&
                  selectedOptions["면적"][1] === 50
                ))
                ? colorStyles.main // 활성화 상태의 테두리 색상
                : "", // 기본 테두리 색상
            borderWidth:
              selectedCategory === "면적" ||
              (selectedOptions["면적"]?.length > 0 &&
                !(
                  selectedOptions["면적"][0] === 0 &&
                  selectedOptions["면적"][1] === 50
                ))
                ? "2px"
                : "",
            fontWeight:
              selectedCategory === "면적" ||
              (selectedOptions["면적"]?.length > 0 &&
                !(
                  selectedOptions["면적"][0] === 0 &&
                  selectedOptions["면적"][1] === 50
                ))
                ? "bold"
                : "",
          }}
        >
          {selectedOptions["면적"]?.length > 0
            ? selectedOptions["면적"][0] === 0 &&
              selectedOptions["면적"][1] === 10
              ? "10평 이하"
              : selectedOptions["면적"][0] === 10 &&
                selectedOptions["면적"][1] === 20
              ? "10평대"
              : selectedOptions["면적"][0] === 20 &&
                selectedOptions["면적"][1] === 30
              ? "20평대"
              : selectedOptions["면적"][0] === 30 &&
                selectedOptions["면적"][1] === 40
              ? "30평대"
              : selectedOptions["면적"][0] === 40 &&
                selectedOptions["면적"][1] === 50
              ? "40평대"
              : selectedOptions["면적"][0] === 50 &&
                selectedOptions["면적"][1] === 50
              ? "50평 이상"
              : selectedOptions["면적"][0] === 0 &&
                selectedOptions["면적"][1] === 50
              ? "면적"
              : `${selectedOptions["면적"][0]}평 ~ ${selectedOptions["면적"][1]}평`
            : "면적"}
        </button>
        <button
          key={"세대수"}
          onClick={() => handleCategoryClick("세대수")}
          className={styles.button}
          style={{
            color:
              selectedCategory === "세대수" ||
              (selectedOptions["세대수"]?.length > 0 &&
                !(
                  selectedOptions["세대수"][0] === 0 &&
                  selectedOptions["세대수"][1] === 5000
                ))
                ? colorStyles.main // 활성화 상태의 텍스트 색상
                : "", // 기본 텍스트 색상
            borderColor:
              selectedCategory === "세대수" ||
              (selectedOptions["세대수"]?.length > 0 &&
                !(
                  selectedOptions["세대수"][0] === 0 &&
                  selectedOptions["세대수"][1] === 5000
                ))
                ? colorStyles.main // 활성화 상태의 테두리 색상
                : "", // 기본 테두리 색상

            borderWidth:
              selectedCategory === "세대수" ||
              (selectedOptions["세대수"]?.length > 0 &&
                !(
                  selectedOptions["세대수"][0] === 0 &&
                  selectedOptions["세대수"][1] === 5000
                ))
                ? "2px"
                : "",
            fontWeight:
              selectedCategory === "세대수" ||
              (selectedOptions["세대수"]?.length > 0 &&
                !(
                  selectedOptions["세대수"][0] === 0 &&
                  selectedOptions["세대수"][1] === 5000
                ))
                ? "bold"
                : "",
          }}
        >
          {selectedOptions["세대수"]?.length > 0
            ? selectedOptions["세대수"][0] === 100 &&
              selectedOptions["세대수"][1] === 5000
              ? "100세대 이상"
              : selectedOptions["세대수"][0] === 500 &&
                selectedOptions["세대수"][1] === 5000
              ? "500세대 이상"
              : selectedOptions["세대수"][0] === 1000 &&
                selectedOptions["세대수"][1] === 5000
              ? "1000세대 이상"
              : selectedOptions["세대수"][0] === 3000 &&
                selectedOptions["세대수"][1] === 5000
              ? "3000세대 이상"
              : selectedOptions["세대수"][0] === 5000 &&
                selectedOptions["세대수"][1] === 5000
              ? "5000세대 이상"
              : selectedOptions["세대수"][0] === 0 &&
                selectedOptions["세대수"][1] === 5000
              ? "세대수"
              : `${selectedOptions["세대수"][0]}세대 ~ ${selectedOptions["세대수"][1]}세대`
            : "세대수"}
        </button>
        <button
          key={"분양상태"}
          onClick={() => handleCategoryClick("분양상태")}
          className={styles.button}
          style={{
            color:
              selectedOptions["분양상태"] != null ||
              selectedCategory === "분양상태"
                ? colorStyles.main // 활성화 상태의 텍스트 색상
                : "", // 기본 텍스트 색상
            borderColor:
              selectedOptions["분양상태"] != null ||
              selectedCategory === "분양상태"
                ? colorStyles.main // 활성화 상태의 테두리 색상
                : "", // 기본 테두리 색상
            borderWidth:
              selectedOptions["분양상태"] != null ||
              selectedCategory === "분양상태"
                ? "2px"
                : "",
            fontWeight:
              selectedOptions["분양상태"] != null ||
              selectedCategory === "분양상태"
                ? "bold"
                : "",
          }}
        >
          {selectedOptions["분양상태"] != null
            ? selectedOptions["분양상태"] === "future"
              ? "분양전"
              : selectedOptions["분양상태"] === "now"
              ? "분양중"
              : selectedOptions["분양상태"] === "past"
              ? "분양완료"
              : "분양상태"
            : "분양상태"}
        </button>
        {/* <button
          key={"주택수"}
          onClick={() => handleCategoryClick("주택수")}
          className={styles.button}
          style={{
            color:
              selectedOptions["주택수"] != null || selectedCategory === "주택수"
                ? colorStyles.main // 활성화 상태의 텍스트 색상
                : "", // 기본 텍스트 색상
            borderColor:
              selectedOptions["주택수"] != null || selectedCategory === "주택수"
                ? colorStyles.main // 활성화 상태의 테두리 색상
                : "", // 기본 테두리 색상
            borderWidth:
              selectedOptions["주택수"] != null || selectedCategory === "주택수"
                ? "2px"
                : "",
            fontWeight:
              selectedOptions["주택수"] != null || selectedCategory === "주택수"
                ? "bold"
                : "",
          }}
        >
          {selectedOptions["주택수"] != null
            ? selectedOptions["주택수"]
              ? "포함"
              : "미포함"
            : "주택수"}
        </button> */}
      </div>
      {showOptions && (
        <div className={styles.optionsModal} ref={optionsContainerRef}>
          <div className={styles.options}>
            <section
              key={"지역"}
              className={styles.categorySection}
              ref={(el) => (categoryRefs.current["지역"] = el)}
            >
              <RegionCategorySection
                initData={selectedOptions["지역"]}
                handleOptionSingleClick={handleOptionSingleClick}
                isReset={isReset}
              />
            </section>
            <section
              key={"가격"}
              className={styles.categorySection}
              ref={(el) => (categoryRefs.current["가격"] = el)}
            >
              <CostSlider
                initData={selectedOptions["가격타입"]}
                initValues={selectedOptions["가격"]}
                handlePriceChange={handleSliderChange}
                handleOptionSingleClick={handleOptionSingleClick}
                isReset={isReset}
              />
            </section>

            <section
              key={"건물종류"}
              className={styles.categorySection}
              ref={(el) => (categoryRefs.current["건물종류"] = el)}
            >
              <HouseTypeSection
                initData={selectedOptions["건물종류"]}
                handleOptionSingleClick={handleOptionSingleClick}
                isReset={isReset}
              />
            </section>
            <section
              key={"면적"}
              className={styles.categorySection}
              ref={(el) => (categoryRefs.current["면적"] = el)}
            >
              <SpaceSizeSection
                initData={selectedOptions["면적"]}
                handleSpaceChange={handleSliderChange}
                handleOptionSingleClick={handleOptionSingleClick}
                isReset={isReset}
              />
            </section>
            <section
              key={"세대수"}
              className={styles.categorySection}
              ref={(el) => (categoryRefs.current["세대수"] = el)}
            >
              <HouseholdSection
                initData={selectedOptions["세대수"]}
                handleNumberChange={handleSliderChange}
                handleOptionSingleClick={handleOptionSingleClick}
                isReset={isReset}
              />
            </section>
            <section
              key={"분양상태"}
              className={styles.categorySection}
              ref={(el) => (categoryRefs.current["분양상태"] = el)}
            >
              <SaleStatusSection
                initData={selectedOptions["분양상태"]}
                handleOptionSingleClick={handleOptionSingleClick}
                isReset={isReset}
                houseType={temporarySelection["건물종류"]}
              />
            </section>
            {/* <section
              key={"주택수"}
              className={styles.categorySection}
              ref={(el) => (categoryRefs.current["주택수"] = el)}
            >
              <NumberofHouseSection
                initData={selectedOptions["주택수"]}
                handleOptionSingleClick={handleOptionSingleClick}
                isReset={isReset}
                houseType={temporarySelection["건물종류"]}
              />
            </section> */}
          </div>
          <div className={styles.buttonGroup}>
            <button
              className={styles.applyButton}
              style={{ backgroundColor: colorStyles.main }}
              onClick={handleApply}
            >
              필터로 검색
            </button>
            <div className={styles.resetButtonSection}>
              <SlRefresh className={styles.resetButton} onClick={handleReset} />
              <span style={{ fontSize: "12px" }}>초기화</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryButtonBar;
